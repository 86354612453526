import React, { Component } from 'react'
// import { Polar } from 'react-chartjs-2';
import { commonService } from "../../Utils/common.service";
import { connect } from "react-redux";
import chat from "../../assets/images/icons/chat.svg";
import done from "../../assets/images/icons/done.svg";
import { RadialBarChart, RadialBar, Legend, Tooltip } from 'recharts';
import DataNotFoundImg from '../../assets/images/widget-no-data.svg'
export class TopPerformance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaignSummaryType: "Live",
            closedCount: 0,
            liveCount: 0,
            radialData: [],
            backgroundColor: [
                '#55efc4',
                '#74b9ff',
                '#a29bfe',
                '#ffc658',
                '#ffeaa7',
                '#fab1a0',
                '#87D3C4',
                '#e5ed57',
                '#ffc658'
            ]
        }
    }
    handleCloseLiveClick = async (type, id) => {
        // console.log(type)
        if (id === 'live') {
            document.getElementById(id).style.opacity = 1;
            document.getElementById('close').style.opacity = 0.6;
        }
        else if (id === 'close') {
            document.getElementById(id).style.opacity = 1;
            document.getElementById('live').style.opacity = 0.6;
        }
        await this.setState({
            campaignSummaryType: type
        })
        this.props.getLoader(true)
        this.getCampaignSummery()
    }
    componentDidMount() {
        this.getCampaignSummery()
        this.getCampaignOverview()
        this.props.onRef(this)
    }

    getCampaignOverview = async() => {
        let request = this.props.commonParmeter;
        let programCode = window.sessionStorage.getItem('programCode')
        let closedCount = 0;
        let liveCount = 0;
        await commonService.CampaignOverview(request.storeCode, request.startDate, request.endDate, programCode)
            .then(response => {
                if (response.isSuccess === "true" && response.data !== null) {
                    for (let i = 0; i < response.data.length; i++) {
                        if (response.data[i].campaignStatus === "Closed") {
                            closedCount = closedCount + parseInt(response.data[i].campaignCount)
                        }
                        else if (response.data[i].campaignStatus === "Live") {
                            liveCount = liveCount + parseInt(response.data[i].campaignCount)
                        }
                    }
                }

            })
        this.setState({
            closedCount: closedCount,
            liveCount: liveCount
        })
    }
    getCampaignSummery() {
        let request = this.props.commonParmeter;
        let programCode = window.sessionStorage.getItem('programCode')
        commonService.CampaignSummary(request.storeCode, request.startDate, request.endDate, programCode, this.state.campaignSummaryType)
            .then(response => {
                if (response.isSuccess === "true" && response.data !== null) {

                    this.props.getLoader(false)
                    // let resultKey = Object.keys(response.data[0]).map(e => (e))
                    // let resultVal = Object.keys(response.data[0]).map(e => (response.data[0][e]))
                    for (let i = 0; i < response.data.length; i++) {

                        // var resultRadial = Object.keys(response.data[0]).map(e => ({ name: e, count: response.data[0][e] }))
                        var resultRadial = response.data.map(e => ({ name: e.kpi, count: e.countValue }))
                    }
                    for (let i = 0; i < response.data.length; i++) {
                        let bgColorChart = this.state.backgroundColor
                        resultRadial[i].fill = bgColorChart[i]
                    }
                    this.setState({
                        radialData: resultRadial
                    })
                } else {
                    // alert('No Record Found In Campaign Overview!!')
                    this.setState({
                        radialData: []
                    })
                    this.props.getLoader(false)
                }
            })
    }
   
    render() {
        const { closedCount, liveCount } = this.state
        return (
            <React.Fragment>
                <div className="place_order1">
                    <button id="close"  onClick={() => this.handleCloseLiveClick("Closed", "close")}>Closed<p><img src={done} className="images" alt="" /><b className="number">{closedCount}</b></p> </button>
                    <button id="live" style={{ opacity: '1' }} onClick={() => this.handleCloseLiveClick("Live", "live")}>Live<p><img src={chat} className="images" alt="" /><b className="number">{liveCount}</b></p></button>
                </div>
                <div className="place-chart">
                    <div className='graph-con'>
                       { this.state.radialData.length===0?<img className="imagesize" alt="" src={DataNotFoundImg}/>:
                        <RadialBarChart width={500} height={300} cx={150} cy={150} innerRadius={20} outerRadius={140} barSize={10} data={this.state.radialData.length ? this.state.radialData : []}>
                            <RadialBar minAngle={15} label={{ position: 'insideStart', fill: '#000' }} background clockWise dataKey="count" />
                            <Legend iconSize={10} width={120} height={140} layout="vertical" verticalAlign="middle" wrapperStyle={{
                                top: 0,
                                left: 320,
                                width: 200,
                                lineHeight: '24px',
                            }} />
                            <Tooltip  labelFormatter={(index) =>this.state.radialData.length !== 0 ? this.state.radialData[index].name : ''}/>
                        </RadialBarChart>
                        }
                        {
                            !this.state.radialData.length &&
                            // <p className="text-danger">No Record Found !!</p>
                            <div className="alert alert-danger performance">
                                <strong>No Record Found !!</strong>
                            </div>
                        }
                        {/* <Polar data={this.state.charData} />     */}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        commonParmeter: state.commonParmeter
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCommonParmeter: (obj) => { dispatch({ type: 'GET_COMMON_PARMETER', payload: obj }) },
        getLoader: (bool) => { dispatch({ type: 'GET_LOADER', payload: bool }) },
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(TopPerformance);
import React, { Component } from "react";
// import { Pie } from "react-chartjs-2";
import { commonService } from "../../Utils/common.service";
import { connect } from "react-redux";
import { PieChart, Pie, Tooltip, Legend, Cell } from 'recharts';
import DataNotFoundImg from '../../assets/images/widget-no-data.svg'
// const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const COLORS = ['#5d62b5', '#29c3bf'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

// const renderCustomizedLabel = (props) => {
//   const RADIAN = Math.PI / 180;
//   const {
//     cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
//     fill, payload, percent, value,
//   } = props;
//   const sin = Math.sin(-RADIAN * midAngle);
//   const cos = Math.cos(-RADIAN * midAngle);
//   const sx = cx + (outerRadius + 10) * cos;
//   const sy = cy + (outerRadius + 10) * sin;
//   const mx = cx + (outerRadius + 30) * cos;
//   const my = cy + (outerRadius + 30) * sin;
//   const ex = mx + (cos >= 0 ? 1 : -1) * 22;
//   const ey = my;
//   const textAnchor = cos >= 0 ? 'start' : 'end';

//   return (
//     <g>
//       {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text> */}
//       <Sector
//         cx={cx}
//         cy={cy}
//         innerRadius={innerRadius}
//         outerRadius={outerRadius}
//         startAngle={startAngle}
//         endAngle={endAngle}
//         fill={fill}
//       />
//       {/* <Sector
//         cx={cx}
//         cy={cy}
//         startAngle={startAngle}
//         endAngle={endAngle}
//         innerRadius={outerRadius + 6}
//         outerRadius={outerRadius + 10}
//         fill={fill}
//       /> */}
//       {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
//       <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
//       <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text> */}
//       <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
//         {`${(percent * 100).toFixed(2)}%`}
//       </text>
//     </g>
//   );
// };
export class PieChar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [],
    }
  }

  componentDidMount() {
    this.getCampaignSouceData()
    this.props.onRef(this)
  }
  getCampaignSouceData() {
    let request = this.props.commonParmeter;
    let programCode = window.sessionStorage.getItem('programCode')

    commonService.CampaignSouce(request.storeCode, request.startDate, request.endDate, programCode)
      .then(response => {
        if (response.isSuccess === "true") {
          this.props.getLoader(false)
          var resultData = response.data.map(e => ({ name: e.contacted, value: parseInt(e.customerCount) }))
          this.setState({
            chartData: resultData
          })
        } else {
          // alert('No Record Found In Campaign Source!!')
          this.setState({
            chartData: []
          })
          this.props.getLoader(false)
        }
      })
  }
  render() {
    const { chartData } = this.state
    return (
      <React.Fragment>
        {this.state.chartData.length === 0?<img className="imagesize" alt="" src={DataNotFoundImg}/>:
        <PieChart width={250} height={350}>
          <Pie
            data={chartData.length ? chartData : []}
            cx={120}
            cy={150}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {
              chartData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
            }
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
  }
        {
          !chartData.length &&
          // <p className="text-danger">No Record Found !!</p>
          <div className="alert alert-danger piechart">
            <strong>No Record Found !!</strong>
          </div>
        }
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    commonParmeter: state.commonParmeter
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCommonParmeter: (obj) => { dispatch({ type: 'GET_COMMON_PARMETER', payload: obj }) },
    getLoader: (bool) => { dispatch({ type: 'GET_LOADER', payload: bool }) },
  };
};



export default connect(mapStateToProps, mapDispatchToProps)(PieChar);
import moment from "moment";

const iState = {
  commonParmeter: {
    programCode: "",
    storeCode: [],
    // startDate: moment().subtract(10, "days").format("MM/DD/YYYY"),
    // endDate: moment().format("MM/DD/YYYY"),
    // startDate: moment().subtract(6, "month").format("MM/DD/YYYY"),
    //    startDate:moment().subtract(29, "days").format("MM/DD/YYYY"),
    //    endDate:"",
    orderBy: "",
    type: "",
    limit: 5,
  },
  reportVideoCallSummary: [],
  reportCampaignSummary: [],
  reportOptionInputSummary: [],
  reportCtaAction: [],
  reportBellTicketSummary: [],
  reportBellChatDetails: [],
  reportBellAppointmentSummary: [],
  reportStaffLeaderBoardSummary: [],
  reportPhygitalSummary: [],
  filterStoresData: [],
  region: [],
  filterState: [],
  city: [],
  storeCode: [],
  loader: true,
};
const reducers = (state = iState, action) => {
  switch (action.type) {
    case "GET_REPORT_BELL_TICKET_SUMMARY":
      return {
        ...state,
        reportBellTicketSummary: action.payload,
      };
    case "GET_REPORT_OPTION_INPUT_SUMMARY":
      return {
        ...state,
        reportOptionInputSummary: action.payload,
      };
    case "GET_CTA_ACTION":
      return {
        ...state,
        reportCtaAction: action.payload,
      };
    case "REGION_DATA":
      return {
        ...state,
        region: action.payload,
      };
    case "STORE_DATA":
      return {
        ...state,
        storeCode: action.payload,
      };
    case "STATE_DATA":
      return {
        ...state,
        filterState: action.payload,
      };
    case "CITY_DATA":
      return {
        ...state,
        city: action.payload,
      };
    case "FILTER_STORE_DATA":
      return {
        ...state,
        filterStoresData: action.payload,
      };
    case "GET_STAFF_LEADER_BOARD_SUMMARY":
      return {
        ...state,
        reportStaffLeaderBoardSummary: action.payload,
      };
    case "GET_BELL_CHAT_SUMMARY":
      return {
        ...state,
        reportBellChatDetails: action.payload,
      };
    case "GET_PHYGITAL_SUMMARY":
      return {
        ...state,
        reportPhygitalSummary: action.payload,
      };
    case "GET_LOADER":
      return {
        ...state,
        loader: action.payload,
      };
    case "GET_BELL_APPOINTMENT_SUMMARY":
      return {
        ...state,
        reportBellAppointmentSummary: action.payload,
      };
    case "GET_REPORT_CAMPAIGN_SUMMARY":
      return {
        ...state,
        reportCampaignSummary: action.payload,
      };
    case "GET_VIDEO_CALL_SUMMARY":
      return {
        ...state,
        reportVideoCallSummary: action.payload,
      };
    case "GET_COMMON_PARMETER":
      return {
        ...state,
        commonParmeter: action.payload,
      };
    default:
      return state;
  }
};
export default reducers;

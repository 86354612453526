import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./component/shared/Home";
import PrivateRoute from "./Utils/PrivateRoute";
import Login from "../src/component/Login";
import "react-notifications/lib/notifications.css";
function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact component={Login} />
          <PrivateRoute path="/dashboard" exact component={Home} />
          {/* <PrivateRoute path="/MotherhoodDashboard" exact component={Home} /> */}
          <PrivateRoute
            path="/reports/campaign-summary"
            exact
            component={Home}
          />
          <PrivateRoute
            path="/reports/phygital-summary"
            exact
            component={Home}
          />
          <PrivateRoute
            path="/reports/bell-chatDetails"
            exact
            component={Home}
          />
          <PrivateRoute
            path="/reports/staff-leader-board"
            exact
            component={Home}
          />
          <PrivateRoute
            path="/reports/appointment-summary"
            exact
            component={Home}
          />
          <PrivateRoute
            path="/reports/whatsapp-option-input-report"
            exact
            component={Home}
          />
          <PrivateRoute path="/reports/ticket-summary" exact component={Home} />
          <PrivateRoute path="/reports/cta-action" exact component={Home} />
          <PrivateRoute path="/report-bulk-download" exact component={Home} />
          {/* <PrivateRoute path="/reports/mh-ticket-summary" exact component={Home}/> */}
          {/* <PrivateRoute
            path="/reports/videocall-summary"
            exact
            component={Home}
          /> */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;

import config from "../config";

export const commonService = {
  CampaignSouce,
  CampaignOverview,
  AppointmentOverview,
  DashboardTopCampaignsList,
  AppointmentTrendDaywise,
  AppointmentTrendWeekwise,
  DashboardTopItemSoldList,
  CampaignSummary,
  BellChatSummery,
  BellAppointmentSummery,
  BellAppointmentDetails,
  BellCampaignSummery,
  BellCampaignDetailSummery,
  BellChatDetails,
  StaffLeaderBoardSummary,
  StaffPerformanceDetails,
  GetShopsterProgramCode,
  PhygitalSummary,
  PhygitalDetails,
  OptionInputSummary,
  BellTicketSummery,
  BellTicketDetails,
  CTAActionSummery,
  CTAActionDetails,
  GetBellOptionInpuOutputSummery,
  GetShopsterDashboardbox,
  DashboardBoxLive,
  VideoCallSummary,
  GetTotalTicketCountMH,
  TicketSummaryMH
};
function DashboardBoxLive(programCode) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      programCode: programCode,
    }),
  };

  return fetch(config.apiUrl + "/Reports/DashboardBoxLive", requestOptions)
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}
function GetShopsterDashboardbox(startDate, endDate, programCode) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
    }),
  };

  return fetch(
    config.apiUrl + "/Reports/GetShopsterDashboardbox",
    requestOptions
  )
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

function GetTotalTicketCountMH(startDate, endDate, programCode,ticketSourceName){
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
      ticketSourceName:ticketSourceName 
    }),
  };

  return fetch(
    config.apiUrl + "/Reports/TotalTicketCountMH",
    requestOptions
  )
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}


function CampaignSouce(storeCode, startDate, endDate, programCode) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      storeCode: storeCode,
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
    }),
  };

  return fetch(config.apiUrl + "/Dashboard/CampaignSource", requestOptions)
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

function CampaignOverview(storeCode, startDate, endDate, programCode) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      storeCode: storeCode,
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
    }),
  };

  return fetch(config.apiUrl + "/Dashboard/CampaignOverview", requestOptions)
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

function AppointmentOverview(storeCode, startDate, endDate, programCode) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      storeCode: storeCode,
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
    }),
  };

  return fetch(config.apiUrl + "/Dashboard/AppointmentOverview", requestOptions)
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

function DashboardTopCampaignsList(
  storeCode,
  startDate,
  endDate,
  programCode,
  orderBy,
  type,
  limit
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      storeCode: storeCode,
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
      orderBy: orderBy,
      type: type,
      limit: limit,
    }),
  };

  return fetch(
    config.apiUrl + "/Dashboard/DashboardTopCampaignsList",
    requestOptions
  )
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

function DashboardTopItemSoldList(
  storeCode,
  startDate,
  endDate,
  programCode,
  orderBy,
  type,
  limit
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      storeCode: storeCode,
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
      orderBy: orderBy,
      type: type,
      limit: limit,
    }),
  };

  return fetch(
    config.apiUrl + "/Dashboard/DashboardTopItemSoldList",
    requestOptions
  )
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

function AppointmentTrendDaywise(
  storeCode,
  startDate,
  endDate,
  programCode,
  type
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      storeCode: storeCode,
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
      type: type,
    }),
  };

  return fetch(
    config.apiUrl + "/Dashboard/AppointmentTrendDaywise",
    requestOptions
  )
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

function AppointmentTrendWeekwise(
  storeCode,
  startDate,
  endDate,
  programCode,
  type
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      storeCode: storeCode,
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
      type: type,
    }),
  };

  return fetch(
    config.apiUrl + "/Dashboard/AppointmentTrendWeekwise",
    requestOptions
  )
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

function CampaignSummary(storeCode, startDate, endDate, programCode, type) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      storeCode: storeCode,
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
      type: type,
    }),
  };

  return fetch(config.apiUrl + "/Dashboard/CampaignSummary", requestOptions)
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

function BellChatSummery(storeCode, startDate, endDate, programCode) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      storeCode: storeCode,
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
    }),
  };

  return fetch(config.apiUrl + "/Reports/BellChatSummery", requestOptions)
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

function BellAppointmentSummery(storeCode, startDate, endDate, programCode) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      storeCode: storeCode,
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
    }),
  };

  return fetch(
    config.apiUrl + "/Reports/BellAppointmentSummery",
    requestOptions
  )
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

function BellAppointmentDetails(storeCode, startDate, endDate, programCode) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      storeCode: storeCode,
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
    }),
  };

  return fetch(
    config.apiUrl + "/Reports/BellAppointmentDetails",
    requestOptions
  )
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

function BellCampaignSummery(storeCode, startDate, endDate, programCode) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      storeCode: storeCode,
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
    }),
  };

  return fetch(config.apiUrl + "/Reports/BellCampaignSummery", requestOptions)
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}
function BellCampaignDetailSummery(
  campaigncode,
  startDate,
  endDate,
  programCode
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      campaigncode: campaigncode,
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
    }),
  };

  return fetch(
    config.apiUrl + "/Reports/BellCampaignDetailSummery",
    requestOptions
  )
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}
function BellChatDetails(storeCode, startDate, endDate, programCode) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      storeCode: storeCode,
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
    }),
  };

  return fetch(config.apiUrl + "/Reports/BellChatDetails", requestOptions)
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}
function StaffLeaderBoardSummary(storeCode, startDate, endDate, programCode) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      storeCode: storeCode,
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
    }),
  };

  return fetch(
    config.apiUrl + "/Reports/StaffLeaderBoardSummary",
    requestOptions
  )
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}
function StaffPerformanceDetails(storeCode, startDate, endDate, programCode) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      storeCode: storeCode,
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
    }),
  };

  return fetch(
    config.apiUrl + "/Reports/StaffPerformanceDetails",
    requestOptions
  )
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

function GetShopsterProgramCode(programCode) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      programCode: programCode,
    }),
  };

  return fetch(
    config.apiUrl + "/Dashboard/GetShopsterProgramCode",
    requestOptions
  )
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

function VideoCallSummary(storeCode, startDate, endDate, programCode) {
    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "cache-control": "no-cache",
      },
      body: JSON.stringify({
        storeCode: storeCode,
        startDate: startDate,
        endDate: endDate,
        programCode: programCode,
      }),
    };
  
    return fetch(config.apiUrl + "/Reports/VideoCallSummary", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        return result;
      });
  }

function PhygitalSummary(storeCode, startDate, endDate, programCode) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      storeCode: storeCode,
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
    }),
  };

  return fetch(config.apiUrl + "/Reports/PhygitalSummary", requestOptions)
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

function PhygitalDetails(storeCode, startDate, endDate, programCode) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      storeCode: storeCode,
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
    }),
  };

  return fetch(config.apiUrl + "/Reports/PhygitalDetails", requestOptions)
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

function OptionInputSummary(startDate, endDate, programCode) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
    }),
  };

  return fetch(config.apiUrl + "/Reports/OptionInputSummary", requestOptions)
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

function BellTicketSummery(storeCode, startDate, endDate, programCode) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      storeCode: storeCode,
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
    }),
  };

  return fetch(config.apiUrl + "/Reports/BellTicketSummery", requestOptions)
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}
function TicketSummaryMH(startDate, endDate, programCode, ticketSourceName) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
      ticketSourceName: ticketSourceName,
    }),
  };

  return fetch(config.apiUrl + "/Reports/TicketSummaryMH", requestOptions)
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}
function BellTicketDetails(storeCode, startDate, endDate, programCode) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      storeCode: storeCode,
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
    }),
  };

  return fetch(config.apiUrl + "/Reports/BellTicketDetails", requestOptions)
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

function CTAActionSummery(startDate, endDate, programCode) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
    }),
  };

  return fetch(config.apiUrl + "/Reports/CTAActionSummery", requestOptions)
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

function CTAActionDetails(startDate, endDate, programCode) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
    }),
  };

  return fetch(config.apiUrl + "/Reports/CTAActionDetails", requestOptions)
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

function GetBellOptionInpuOutputSummery(startDate, endDate, programCode) {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
    },
    body: JSON.stringify({
      startDate: startDate,
      endDate: endDate,
      programCode: programCode,
    }),
  };

  return fetch(
    config.apiUrl + "/Reports/GetBellOptionInpuOutputSummery",
    requestOptions
  )
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
}

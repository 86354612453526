import React, { Component } from 'react'
import { commonService } from "../../Utils/common.service";
import { connect } from "react-redux";
// import { Bar } from 'react-chartjs-2';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
// import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';
import DataNotFoundImg from '../../assets/images/widget-no-data.svg'
export class CampaignPerformance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: [],
            campaignListType: 'Conversion',
            campaignListOrderBy: "DESC",
        }
    }
    componentDidMount() {
        this.getTopCampaignPerformance()
        this.props.onRef(this)

    }

    handleConversionInteraction = async (type) => {
        // console.log(type)
        await this.setState({
            campaignListType: type
        })
        this.props.getLoader(true)
        this.getTopCampaignPerformance()
    }

    handleOrder = async (event) => {
        await this.setState({
            campaignListOrderBy: event.target.value
        })
        this.props.getLoader(true)
        this.getTopCampaignPerformance()
    }

    getTopCampaignPerformance() {
        let request = this.props.commonParmeter;
        let programCode = window.sessionStorage.getItem('programCode')
        commonService.DashboardTopCampaignsList(request.storeCode, request.startDate, request.endDate, programCode, this.state.campaignListOrderBy, this.state.campaignListType, request.limit)
            .then(response => {console.log("re ",response)
                if (response.isSuccess === "true" && response.data !== null) {
                    this.props.getLoader(false)
                    for (let i = 0; i < response.data.length; i++) {
                        var resultData = response.data.map(e => ({ name: e.campaignCode, count: e.customerCount }))

                        // if(this.state.campaignListType === 'Conversion'){
                        // var resultData = response.data.map(e => ({ name: e.campaignCode, Conversion: e.customerCount}))
                        // }
                        // else if(this.state.campaignListType === 'Interaction'){
                        //     resultData = response.data.map(e => ({ name: e.campaignCode, Interaction: e.customerCount}))
                        // }
                    }
                   
                    this.setState({
                        chartData: resultData
                    })
                } else {
                    // alert('No Record Found In Campaign Performance!!')
                    this.setState({
                        chartData: []
                    })
                    this.props.getLoader(false)
                }
            })
    }

    render() {
        return (
            <div>
                <div className="header">
                    <h3>Top Campaign Performance</h3>
                    { this.state.chartData.length === 0?undefined: <select id="perform" className="select-campaign" onChange={(e) => this.handleOrder(e)}>
                        <option value="DESC">Top Performing</option>
                        <option value="ASC">Bottom Performing</option>
                    </select>}
                </div>
                <div className="type-div">
                    <div className="radio-styles ">
                        <input type="radio" id="Conversion" name="age" onClick={() => this.handleConversionInteraction("Conversion")} defaultChecked />
                        <label className="label_style" >Conversion</label>
                        <input type="radio" id="Interaction" name="age" onClick={() => this.handleConversionInteraction("Interaction")} />
                        <label className="label_style">Interaction</label>
                    </div>
                </div>
                <div className="description">
                    <div className="graph-con">
                       { 
                       this.state.chartData.length === 0?<img className="imagesize" alt="" src={DataNotFoundImg}/>:<BarChart
                       width={590}
                       height={300}
                       data={this.state.chartData.length ? this.state.chartData : []}
                       margin={{
                           top: 5, right: 90, left: -25, bottom: 5,
                       }}
                       barSize={35}
                   >
                       <CartesianGrid strokeDasharray="3 3" />
                       <XAxis dataKey="name" />
                       <YAxis />
                       <Tooltip />
                       {/* <Legend /> */}
                       <Bar dataKey='count' fill='#5d62b5' />
                       {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
                   </BarChart>}
                        {
                            !this.state.chartData.length &&
                            // <p className="text-danger">No Record Found !!</p>
                            <div className="alert alert-danger performance">
                                <strong>No Record Found !!</strong>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        commonParmeter: state.commonParmeter
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCommonParmeter: (obj) => { dispatch({ type: 'GET_COMMON_PARMETER', payload: obj }) },
        getLoader: (bool) => { dispatch({ type: 'GET_LOADER', payload: bool }) },
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(CampaignPerformance);
import React, { Component } from 'react'
// import { Bar } from 'react-chartjs-2';
import { commonService } from "../../Utils/common.service";
import { connect } from "react-redux";
import icon1 from "../../assets/images/icons/icon1.svg";
import message from "../../assets/images/icons/message.svg";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import DataNotFoundImg from '../../assets/images/widget-no-data.svg'
export class BarChartData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appointmentType: "SelfAppointment",
            countOfAppointmentSelf: 0,
            countOfAppointmentAssist: 0,
            selfPercent: 0,
            assistPercent: 0,
            dayWeekData: 'weekWise',
            appointment: 'day',
            chartData: [],
            showSelfAssist: false
        }
    }

    handleSelfAssisted = async (type, id) => {
        // console.log(type)
        if (id === 'assist') {
            document.getElementById(id).style.opacity = 1;
            document.getElementById('self').style.opacity = 0.6;
        }
        else if (id === 'self') {
            document.getElementById(id).style.opacity = 1;
            document.getElementById('assist').style.opacity = 0.6;
        }
        await this.setState({
            // loader:true,
            appointmentType: type
        })
        this.props.getLoader(true)
        this.getAppointmentOverview()
        // console.log(this.props.commonParmeter)
    }
    componentDidMount() {
        this.getAppointmentOverview()
        // this.getAppointmentDayWeekwise()
        this.getDataPercent()
        this.props.onRef(this)
    }

    getDataPercent = async () => {
        let request = this.props.commonParmeter;
        let programCode = window.sessionStorage.getItem('programCode')
        let selfPercent = 0.00;
        await commonService.AppointmentOverview(request.storeCode, request.startDate, request.endDate, programCode)
            .then(response => {
                if (response.isSuccess === "true" && response.data !== null) {
                    this.props.getLoader(false)
                    let countOfAppointmentSelf = this.state.countOfAppointmentSelf;
                    let countOfAppointmentAssist = this.state.countOfAppointmentAssist
                    for (let i = 0; i < response.data.length; i++) {
                        // appointmentType.push(response.data[i].appointmentType)
                        if (response.data[i].appointmentType === "SelfAppointment") {
                            countOfAppointmentSelf = countOfAppointmentSelf + parseInt(response.data[i].countOfAppointment)
                        }
                        else {
                            countOfAppointmentAssist = countOfAppointmentAssist + parseInt(response.data[i].countOfAppointment)
                        }
                        selfPercent = (countOfAppointmentSelf * 100) / (countOfAppointmentSelf + countOfAppointmentAssist)

                    }
                }
            })

        if (selfPercent === 0.00) {
            this.setState({
                selfPercent: selfPercent,
                assistPercent: selfPercent
            })
        }
        else {
            this.setState({
                selfPercent: selfPercent,
                assistPercent: 100 - selfPercent
            })
        }
    }
    getAppointmentOverview() {
        let request = this.props.commonParmeter;
        let programCode = window.sessionStorage.getItem('programCode')
        if (this.state.appointment === 'day') {
            commonService.AppointmentTrendDaywise(request.storeCode, request.startDate, request.endDate, programCode, this.state.appointmentType)
                .then(response => {
                    if (response.isSuccess === "true" && response.data !== null) {
                        this.props.getLoader(false)
                        let dailyData = []
                        let newData = []
                        for (let i = 0; i < response.data.length; i++) {
                            // let week = []
                            dailyData.push(response.data[i].day)
                            dailyData = [...new Set(dailyData)]
                        }
                        // console.log(dailyData)
                        for (let j = 0; j < dailyData.length; j++) {
                            newData[j] = { name: '', Visited: 0, Cancelled: 0, Unknown: 0 }
                            for (let i = 0; i < response.data.length; i++) {
                                if (response.data[i].day === dailyData[j]) {
                                    newData[j].name = dailyData[j]
                                    if (response.data[i].appointmentstatus === 'Visited') {
                                        newData[j].Visited += parseInt(response.data[i].customerCount)
                                    }
                                    else if (response.data[i].appointmentstatus === 'Cancelled') {
                                        newData[j].Cancelled += parseInt(response.data[i].customerCount)
                                    }
                                    else {
                                        newData[j].Unknown += parseInt(response.data[i].customerCount)
                                    }
                                }
                            }
                        }
                        // console.log(newData)
                        // console.log(resultData)
                        // newData.map(e=>console.log(e.name))
                        var resultData = newData.map(e => ({ name: e.name, Visited: e.Visited, Cancelled: e.Cancelled, Unknown: e.Unknown }))
                        this.setState({
                            chartData: resultData
                        })

                        // console.log(this.state.chartData)

                    } else {
                        // alert('No Record Found In Day Wise Trend!!')
                        this.setState({
                            chartData: []
                        })
                        this.props.getLoader(false)
                    }
                })
        }
        else if (this.state.appointment === 'week') {
            // this.setState({ dayWeekData: 'weekWise' })
            // this.state.dayWeekData = 'dayWise'
            commonService.AppointmentTrendWeekwise(request.storeCode, request.startDate, request.endDate, programCode, this.state.appointmentType)
                .then(response => {
                    if (response.isSuccess === "true" && response.data !== null) {
                        this.props.getLoader(false)
                        // console.log(response.data)
                        let weekData = []
                        let newData = []
                        for (let i = 0; i < response.data.length; i++) {
                            // let week = []
                            weekData.push(response.data[i].week)
                            weekData = [...new Set(weekData)]
                        }
                        for (let j = 0; j < weekData.length; j++) {
                            newData[j] = { name: '', Visited: 0, Cancelled: 0, Unknown: 0 }
                            for (let i = 0; i < response.data.length; i++) {
                                if (response.data[i].week === weekData[j]) {
                                    newData[j].name = weekData[j]
                                    if (response.data[i].appointmentstatus === 'Visited') {
                                        newData[j].Visited += parseInt(response.data[i].customerCount)
                                    }
                                    else if (response.data[i].appointmentstatus === 'Cancelled') {
                                        newData[j].Cancelled += parseInt(response.data[i].customerCount)
                                    }
                                    else {
                                        newData[j].Unknown += parseInt(response.data[i].customerCount)
                                    }
                                }
                            }
                        }
                        var resultData = newData.map(e => ({ name: "Week " + e.name, Visited: e.Visited, Cancelled: e.Cancelled, Unknown: e.Unknown }))
                        this.setState({
                            chartData: resultData
                        })

                        // console.log(resultData)

                    } else {
                        // alert('No Record Found  In Week Wise Trend!!')
                        this.setState({
                            chartData: []
                        })
                        this.props.getLoader(false)
                    }
                })
        }
    }
    getAppointmentDayWeekwise() {
        // this.setState({
        //     showSelfAssist: false
        // })
        // console.log(event.target.value)
        let request = this.props.commonParmeter;
        let programCode = window.sessionStorage.getItem('programCode')
        if (this.state.dayWeekData === 'dayWise') {
            this.setState({
                dayWeekData: 'weekWise',
                appointment: 'day'
            })
            // this.state.dayWeekData = 'weekWise'
            commonService.AppointmentTrendDaywise(request.storeCode, request.startDate, request.endDate, programCode, this.state.appointmentType)
                .then(response => {
                    if (response.isSuccess === "true" && response.data !== null) {
                        this.props.getLoader(false)
                        let dailyData = []
                        let newData = []
                        for (let i = 0; i < response.data.length; i++) {
                            // let week = []
                            dailyData.push(response.data[i].day)
                            dailyData = [...new Set(dailyData)]
                        }
                        // console.log(dailyData)
                        for (let j = 0; j < dailyData.length; j++) {
                            newData[j] = { name: '', Visited: 0, Cancelled: 0, Unknown: 0 }
                            for (let i = 0; i < response.data.length; i++) {
                                if (response.data[i].day === dailyData[j]) {
                                    newData[j].name = dailyData[j]
                                    if (response.data[i].appointmentstatus === 'Visited') {
                                        newData[j].Visited += parseInt(response.data[i].customerCount)
                                    }
                                    else if (response.data[i].appointmentstatus === 'Cancelled') {
                                        newData[j].Cancelled += parseInt(response.data[i].customerCount)
                                    }
                                    else {
                                        newData[j].Unknown += parseInt(response.data[i].customerCount)
                                    }
                                }
                            }
                        }
                        // console.log(newData)
                        // console.log(resultData)
                        // newData.map(e=>console.log(e.name))
                        var resultData = newData.map(e => ({ name: e.name, Visited: e.Visited, Cancelled: e.Cancelled, Unknown: e.Unknown }))
                        this.setState({
                            chartData: resultData
                        })

                        // console.log(this.state.chartData)

                    } else {
                        // alert('No Record Found In Day Wise Trend!!')
                        this.setState({
                            chartData: []
                        })
                        this.props.getLoader(false)
                    }
                })
        }
        else if (this.state.dayWeekData === 'weekWise') {
            this.setState({
                dayWeekData: 'dayWise',
                appointment: 'week'
            })
            // this.state.dayWeekData = 'dayWise'
            commonService.AppointmentTrendWeekwise(request.storeCode, request.startDate, request.endDate, programCode, this.state.appointmentType)
                .then(response => {
                    if (response.isSuccess === "true" && response.data !== null) {
                        this.props.getLoader(false)
                        // console.log(response.data)
                        let weekData = []
                        let newData = []
                        for (let i = 0; i < response.data.length; i++) {
                            // let week = []
                            weekData.push(response.data[i].week)
                            weekData = [...new Set(weekData)]
                        }
                        for (let j = 0; j < weekData.length; j++) {
                            newData[j] = { name: '', Visited: 0, Cancelled: 0, Unknown: 0 }
                            for (let i = 0; i < response.data.length; i++) {
                                if (response.data[i].week === weekData[j]) {
                                    newData[j].name = weekData[j]
                                    if (response.data[i].appointmentstatus === 'Visited') {
                                        newData[j].Visited += parseInt(response.data[i].customerCount)
                                    }
                                    else if (response.data[i].appointmentstatus === 'Cancelled') {
                                        newData[j].Cancelled += parseInt(response.data[i].customerCount)
                                    }
                                    else {
                                        newData[j].Unknown += parseInt(response.data[i].customerCount)
                                    }
                                }
                            }
                        }
                        var resultData = newData.map(e => ({ name: "Week " + e.name, Visited: e.Visited, Cancelled: e.Cancelled, Unknown: e.Unknown }))
                        this.setState({
                            chartData: resultData
                        })

                        // console.log(resultData)

                    } else {
                        // alert('No Record Found  In Week Wise Trend!!')
                        this.setState({
                            chartData: []
                        })
                        this.props.getLoader(false)
                    }
                })
        }
    }

    render() {
        const { chartData, selfPercent, assistPercent } = this.state
        return (
            <React.Fragment>
                <div className="header">
                    <h3>Appointment Overview</h3>
                    {/* <Select placeholder='Weekly' options={regionOptions} /> */}
                   { this.state.chartData.length === 0?undefined:<select defaultValue="dayWise" className="select-campaign" onChange={() => this.getAppointmentDayWeekwise()}>
                        <option value='dayWise'>Daily</option>
                        <option value='weekWise'>Weekly</option>
                    </select>}
                </div>
                <div className={this.state.chartData.length === 0?"graph-con":"description placebox"}>
                    <div className="place_order1" style={{ width: '204px' }}>
                        <button id='self' style={{ opacity: '1' }} onClick={() => this.handleSelfAssisted("SelfAppointment", "self")}>Self <p> <img src={icon1} className="images" alt="" /><b className="number" style={{ fontSize: '15px' }}>{selfPercent.toFixed(2)}%</b></p> </button>
                        <button id='assist' onClick={() => this.handleSelfAssisted("AssistedAppointment", "assist")}>Assisted <p><img src={message} className="images" alt="" /><b className="number" style={{ fontSize: '15px' }}>{assistPercent.toFixed(2)}%</b></p> </button>
                    </div>
                    <div className={this.state.chartData.length === 0?"place-chart":"graph-con"}>
                    { this.state.chartData.length === 0?<img className="imagesize" alt="" src={DataNotFoundImg}/>:
                        <BarChart
                            width={840}
                            height={330}
                            data={chartData.length ? chartData : []}
                            margin={{
                                top: 30, right: 30, left: 15, bottom: 5,
                            }}
                            barSize={35}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />

                            <Bar dataKey="Visited" stackId="a" fill="#5d62b5" />
                            <Bar dataKey="Cancelled" stackId="a" fill="#dc3545" />
                            <Bar dataKey="Unknown" stackId="a" fill="#29c3bf" />

                        </BarChart>}
                        {
                            !chartData.length &&
                            // <p className="text-danger">No Record Found !!</p>
                            <div className="alert alert-danger">
                                <strong>No Record Found !!</strong>
                            </div>
                        }


                    </div>
                </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        commonParmeter: state.commonParmeter,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCommonParmeter: (obj) => { dispatch({ type: 'GET_COMMON_PARMETER', payload: obj }) },
        getLoader: (bool) => { dispatch({ type: 'GET_LOADER', payload: bool }) },
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(BarChartData);

import React, { Component } from "react";
import Collapse from "react-bootstrap/Collapse";
import filter from "../../assets/images/icons/filter.svg";
import PieChart from "../Chart/PieChar";
import BarChart from "../Chart/BarChart";
import TopPerformance from "../Chart/TopPerformance";
import ProductPerformance from "../Chart/ProductPerformance";
import CampaignPerformance from "../Chart/CampaignPerformance";
import { connect } from "react-redux";
import { DateRangePicker } from "rsuite";
import { Spinner } from "react-bootstrap";
import { commonService } from "../../Utils/common.service";
import { endOfDay, format, startOfDay } from "date-fns";
// import { MdInfoOutline } from "react-icons/md";
import { TiArrowSortedDown } from "react-icons/ti";
import { TiArrowSortedUp } from "react-icons/ti";
import InfoIcon from "../../assets/images/info-icon.png";
// import { Table } from "rsuite";
// import { Popover } from "antd";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import subDays from 'date-fns/subDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import moment from "moment";
const predefinedRanges = [

  {
    label: 'Last 7 days',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left'

  },
  {
    label: 'Last month',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left'
  },
  {
    label: 'Last 6 month',
    value: [startOfMonth(addMonths(new Date(), -6)), endOfMonth(addMonths(new Date(), -1))],
    // value: [ new Date(), endOfMonth(addMonths(new Date(), -6))],
    placement: 'left'
  }
];
export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      filterState: [],
      filterCity: [],
      filterRegion: [],
      filterStoreCode: this.props.filterStoresData,
      countryRegion: [],
      updatedRegion: [],
      updatedState: [],
      updatedCity: [],
      updatedStore: [],
      updatedStoreName: [],
      updatedStoreFilter:[],
      changeData: false,
      storeCodeVal: [],
      show: false,
      showCity: false,
      showState: false,
      stateName: [],
      showRegion: false,
      regionName: [],
      regionValue: '',
      cityName: [],
      cityValue: '',
      stateValue: '',
      storeCodeValue: '',
      onClickedCity: true,
      onClickedRegion: true,
      onClickedState: true,
      onClickedStore: true,
      startD: "",
      endD: "",
      labels: [],
      // isInfo:false,
    };
  }
  unique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  ShopstarDashborBox = (parameter) => {
    //  debugger
    let data = parameter;
    console.log("data", data)
    let programCode = window.sessionStorage.getItem("programCode");
    commonService
      .GetShopsterDashboardbox(data.startDate, data.endDate, programCode)
      .then((response) => {
        if (response.isSuccess === "true" && response.data !== null) {
          this.setState({ labels: response.data });
        }
      });
  };
  componentDidMount() {

    let data = this.props.commonParmeter
    data.startDate = moment().subtract(29, "days").format("MM/DD/YYYY")
    data.endDate = moment().format("MM/DD/YYYY")

    this.props.getCommonParmeter(data)


    // new Date(value[0]) =  data.startDate;
    // new Date(value[1]) = data.endDate;

    this.ShopstarDashborBox(data);
    let programCode = window.sessionStorage.getItem("programCode");


    commonService.GetShopsterProgramCode(programCode).then((response) => {
      if (response.isSuccess === "true" && response.data !== null) {
        this.props.getFilterStoredetails(response.data);
        let region = [];
        let state = [];
        let city = [];
        for (let i = 0; i < response.data.length; i++) {
          region.push(response.data[i].region);
          state.push(response.data[i].state);
          city.push(response.data[i].city);
          region = [...new Set(region)].sort();
          state = [...new Set(state)].sort();
          city = [...new Set(city)].sort();
        }
        this.props.getFilterRegionDetails(region);
        this.props.getFilterStateDetails(state);
        this.props.getFilterCityDetails(city);
        this.setState({
          updatedRegion: region,
        })
        // this.setState({
        //   updatedState: state,
        // })
        // this.setState({
        //   updatedCity: city,
        // });
        // this.setState({
        //   updatedStore: ,
        // });
      } else {
        alert("No Record Found!!");
      }
    });

  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  // changeDateHandler(value) {
  //   if (value[0] !== undefined && value[1] !== undefined) {
  //     // let startDate = value[0]._d
  //     // let endDate = value[1]._d
  //     let startDate = value[0]
  //     let endDate = value[1]
  //     let data = this.props.commonParmeter
  //     var maxStartDate = moment().diff(value[0].format('MM/DD/YYYY'), 'days');
  //     var maxEndDate = moment().diff(value[1].format('MM/DD/YYYY'), 'days');
  //     if (maxStartDate >= 0) {
  //       data.startDate = this.convert(startDate)
  //     }
  //     else {
  //       alert("Date must be equal or before today Please enter a valid Start Date")
  //     }
  //     if (maxEndDate >= 0) {
  //       data.endDate = this.convert(endDate)
  //     }
  //     else {
  //       alert("Date must be equal or before today Please enter a valid End Date")
  //     }
  //     if (maxStartDate >= 0 && maxEndDate >= 0) {
  //       this.props.getCommonParmeter(data)
  //       this.piechart.getCampaignSouceData()
  //       this.barchart.getAppointmentOverview()
  //       // this.barchart.getAppointmentDayWeekwise()
  //       this.barchart.getDataPercent()
  //       this.campaignchart.getTopCampaignPerformance()
  //       this.productchart.getTopProductPerformance()
  //       this.topperformchart.getCampaignSummery()
  //       this.topperformchart.getCampaignOverview()
  //     }
  //   }
  // }
  setSelectedData = (value) => {
    //  debugger
    console.log("val date dashboard ", value);
    let data = this.props.commonParmeter;
    //this.setState({startD:value[0],endD:value[1]})

    // if (value[0] !== undefined && value[1] !== undefined) {
    // let startDate = value[0]._d
    // let endDate = value[1]._d
    // let startDate = value[0]
    // let endDate = value[1]
    // let data = this.props.commonParmeter
    // var maxStartDate = moment().diff(value[0], 'days');
    // var maxEndDate = moment().diff(value[1], 'days');

    // console.log('maxStartDate',maxStartDate);
    // console.log('maxEndDate',maxEndDate);
    // console.log('startDate',startDate);
    // console.log('endDate',endDate);
    // if (maxStartDate >= 0) {
    //   data.startDate = this.convert(startDate)
    // }
    // else {
    //   alert("Date must be equal or before today Please enter a valid Start Date")
    // }
    // if (maxEndDate >= 0) {
    //   data.endDate = this.convert(endDate)
    // }
    // else {
    //   alert("Date must be equal or before today Please enter a valid End Date")
    // }
    // if (maxStartDate >= 0 && maxEndDate >= 0) {
    data.startDate = format(new Date(value[0]), "MM/dd/yyyy");
    data.endDate = format(new Date(value[1]), "MM/dd/yyyy");
    console.log("data setSelectedData", data);
    if (data.startDate !== "" && data.endDate !== "") {
      this.ShopstarDashborBox(data);
      this.props.getCommonParmeter(data);
      this.piechart.getCampaignSouceData();
      this.barchart.getAppointmentOverview();
      // this.barchart.getAppointmentDayWeekwise()
      this.barchart.getDataPercent();
      this.campaignchart.getTopCampaignPerformance();
      this.productchart.getTopProductPerformance();
      this.topperformchart.getCampaignSummery();
      this.topperformchart.getCampaignOverview();
    }
    // }
    //}
  };

  handleSetOpen = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  onApplyHandler() {
    debugger
    this.setState({
      storeCodeValue: this.state.storeCodeVal.join()
    })
    // debugger
    if (this.state.storeCodeVal !== null) {
      let commonParmeter = this.props.commonParmeter;
      commonParmeter.storeCode = this.state.storeCodeVal;
      this.props.getCommonParmeter(commonParmeter);
      this.setState({
        show: !this.state.show,
        // cityName: [],
        // stateName: [],
        // regionName: [],
        // storeCodeVal: [],
        changeData: false,
        onClickedStore: true,
      });
      //this.ShopstarDashborBox(this.props.commonParmeter)
      this.piechart.getCampaignSouceData();
      // this.barchart.getAppointmentDayWeekwise()
      this.barchart.getAppointmentOverview();
      this.barchart.getDataPercent();
      this.campaignchart.getTopCampaignPerformance();
      this.productchart.getTopProductPerformance();
      this.topperformchart.getCampaignSummery();
      this.topperformchart.getCampaignOverview();
    }
  }

  onCancelHandler() {
    var items = document.getElementsByName("storeCode");
    for (var i = 0; i < items.length; i++) {
      if (items[i].type === "checkbox") {
        items[i].checked = false;
      }
    }
    this.setState({
      storeCodeVal: [],
      storeCodeValue:'',
    });
  }

  onCancelCityHandler() {
    var items = document.getElementsByName("city");
    for (var i = 0; i < items.length; i++) {
      if (items[i].type === "checkbox") {
        items[i].checked = false;
      }
    }
    this.setState({
      cityName: [],
      cityValue:'',
    });
  }

  onCancelStateHandler() {
    var items = document.getElementsByName("state");
    for (var i = 0; i < items.length; i++) {
      if (items[i].type === "checkbox") {
        items[i].checked = false;
      }
    }
    this.setState({
      stateName: [],
      stateValue:'',
    });
  }
  onCancelRegionHandler() {
    var items = document.getElementsByName("region");
    for (var i = 0; i < items.length; i++) {
      if (items[i].type === "checkbox") {
        items[i].checked = false;
      }
    }
    this.setState({
      regionName: [],
      regionValue:'',
    });
  }

  onSelectAllHandler() {
    document.getElementById("select-all").onchange = function () {
      var checkboxes = document.getElementsByName("storeCode");
      for (var checkbox of checkboxes) {
        checkbox.checked = this.checked;
      }
    };
    var clicked = this.state.onClickedStore;
    if (clicked === true) {
      let store = [];
      if (this.state.changeData === true) {
        let selectedData = this.props.storeCode;
        for (let i = 0; i < selectedData.length; i++) {
          store.push(selectedData[i].store);
        }
      } else {
        let selectedData = this.props.filterStoresData;
        for (let i = 0; i < selectedData.length; i++) {
          store.push(selectedData[i].store);
        }
      }
      this.setState({
        storeCodeVal: store.flat(),
        onClickedStore: false,
      });
    }
    if (clicked === false) {
      this.setState({
        storeCodeVal: [],
        onClickedStore: true,
      });
    }
  }

  onSelectAllCityHandler(val) {
    document.getElementById("select-all-city").onchange = function () {
      var checkboxes = document.getElementsByName("city");
      for (var checkbox of checkboxes) {
        checkbox.checked = this.checked;
      }
    };
    var clicked = this.state.onClickedCity;
    if (clicked === true) {
      let data = this.state.cityName;
      if (this.state.changeData === true) {
        data.push(this.state.filterCity);
      } else {
        data.push(this.props.city);
      }
      this.setState({
        onClickedCity: false,
        cityName: data.flat(),
      });
    }
    if (clicked === false) {
      this.setState({
        cityName: [],
        onClickedCity: true,
      });
    }
    // console.log(this.state.cityName, "cityname select")
  }

  onSelectAllStateHandler() {
    document.getElementById("select-all-state").onchange = function () {
      var checkboxes = document.getElementsByName("state");
      for (var checkbox of checkboxes) {
        checkbox.checked = this.checked;
      }
    };
    var clicked = this.state.onClickedState;
    if (clicked === true) {
      let data = this.state.stateName;
      if (this.state.changeData === true) {
        data.push(this.state.filterState);
      } else {
        data.push(this.props.filterState);
      }
      this.setState({
        onClickedState: false,
        stateName: data.flat(),
      });
    }
    if (clicked === false) {
      this.setState({
        stateName: [],
        onClickedState: true,
      });
    }
  }

  onSelectAllRegionHandler() {
    document.getElementById("select-all-region").onchange = function () {
      var checkboxes = document.getElementsByName("region");
      for (var checkbox of checkboxes) {
        checkbox.checked = this.checked;
      }
    };
    var clicked = this.state.onClickedRegion;
    if (clicked === true) {
      let data = this.state.regionName;
      if (this.state.changeData === true) {
        data.push(this.state.filterRegion);
      } else {
        data.push(this.props.region);
      }
      this.setState({
        onClickedRegion: false,
        regionName: data.flat(),
      });
    }
    if (clicked === false) {
      this.setState({
        regionName: [],
        onClickedRegion: true,
      });
    }
  }

  onApplyCityHandler() {
    //  debugger
    // console.log("citydata", this.state.cityName)
    if (this.state.cityName !== null) {
      let programcode = this.props.filterStoresData;
      let data = this.state.cityName;
      let selectedData = [];
      for (let i = 0; i < data.length; i++) {
        selectedData.push(programcode.filter((obj) => obj.city === data[i]));
      }
      // console.log("selectedData", selectedData)
      this.props.getFilterStoreCodeDetails(selectedData.flat());
      var filteredData = selectedData.flat();
      // console.log("filterdata", filteredData)
      let region = [];
      let state = [];
      let city = [];
      let store = [];
      let storeName = [];
      let storeCodeName = [];
      for (let i = 0; i < filteredData.length; i++) {
        region.push(filteredData[i].region);
        state.push(filteredData[i].state);
        city.push(filteredData[i].city);
        store.push(filteredData[i].store);
        storeName.push(filteredData[i].storeName);
        storeCodeName.push(filteredData[i].store+"-"+filteredData[i].storeName)
        region = [...new Set(region)].sort();
        state = [...new Set(state)].sort();
        city = [...new Set(city)].sort();
        store = [...new Set(store)].sort();
        storeName = [...new Set(storeName)].sort();
        storeCodeName = [...new Set(storeCodeName)].sort();
      }
      this.setState({
        showCity: false,
        changeData: true,
        filterRegion: region,
        filterState: state,
        filterCity: city,
        onClickedCity: true,
        // updatedStoreFilter: store,
        // updatedStore: store,
        updatedStoreFilter: storeCodeName,
        updatedStore: storeCodeName,
        updatedStoreName: storeName,
        cityValue: this.state.cityName.join()
      });
    }
  }

  onApplyStateHandler() {
    if (this.state.stateName !== null) {
      let programcode = this.props.filterStoresData;
      let data = this.state.stateName;
      let selectedData = [];
      for (let i = 0; i < data.length; i++) {
        selectedData.push(programcode.filter((obj) => obj.state === data[i]));
      }
      this.props.getFilterStoreCodeDetails(selectedData.flat());
      var filteredData = selectedData.flat();
      let region = [];
      let state = [];
      let city = [];
      for (let i = 0; i < filteredData.length; i++) {
        region.push(filteredData[i].region);
        state.push(filteredData[i].state);
        city.push(filteredData[i].city);
        region = [...new Set(region)].sort();
        state = [...new Set(state)].sort();
        city = [...new Set(city)].sort();
      }
      this.setState({
        showState: false,
        changeData: true,
        filterRegion: region,
        filterState: state,
        filterCity: city,
        onClickedState: true,
        updatedCity: city,
        stateValue: this.state.stateName.join()
      });
    }
  }

  onApplyRegionHandler() {
    // console.log("this.props.region",this.props.region)
    // debugger
    if (this.state.regionName !== null) {
      let programcode = this.props.filterStoresData;
      let data = this.state.regionName;
      let selectedData = [];
      for (let i = 0; i < data.length; i++) {
        selectedData.push(programcode.filter((obj) => obj.region === data[i]));
      }
      // console.log(selectedData.flat())
      this.props.getFilterStoreCodeDetails(selectedData.flat());
      var filteredData = selectedData.flat();
      let region = [];
      let state = [];
      let city = [];
      for (let i = 0; i < filteredData.length; i++) {
        region.push(filteredData[i].region);
        state.push(filteredData[i].state);
        city.push(filteredData[i].city);
        region = [...new Set(region)].sort();
        state = [...new Set(state)].sort();
        city = [...new Set(city)].sort();
      }
      // console.log(region, 're')
      // console.log(state, 'state')
      // console.log(city, 'c')
      this.setState({
        showRegion: false,
        changeData: true,
        filterRegion: region,
        filterState: state,
        filterCity: city,
        onClickedRegion: true,
        updatedState: state,
        regionValue: this.state.regionName.join()
      });
      // this.setState({
      //   updatedState: state,
      // })
    }
  }

  onCityChangeHandler(val) {
    // console.log(this.state.cityName)
    let city = this.state.cityName;
    if (city.includes(val)) {
      const index = city.indexOf(val);
      if (index > -1) {
        city.splice(index, 1);
      }
    } else {
      city.push(val);
    }
    city = [...new Set(city)].sort();
    this.setState({
      cityName: city,
      storeCodeVal: [],
    });
  }

  onStateChangeHandler(val) {
    let state = this.state.stateName;
    if (state.includes(val)) {
      const index = state.indexOf(val);
      if (index > -1) {
        state.splice(index, 1);
      }
    } else {
      state.push(val);
    }
    state = [...new Set(state)].sort();
    this.setState({
      stateName: state,
      storeCodeVal: [],
      cityName: [],
    });
  }

  onRegionChangeHandler(val) {
    // debugger
    let region = this.state.regionName;
    if (region.includes(val)) {
      const index = region.indexOf(val);
      if (index > -1) {
        region.splice(index, 1);
      }
    } else {
      region.push(val);
    }
    region = [...new Set(region)].sort();
    this.setState({
      regionName: region,
      storeCodeVal: [],
      cityName: [],
      stateName: [],
    });
  }
  //search filter region
  searchRegion = (e) => {
    // e.preventDefault()
    console.log("this.props.region",this.props.region)
    let regionFilter = this.props.region;
    const newData = []
    let val = e.target.value
    const newArray = regionFilter.filter(item => {
      if (item.toLowerCase().includes(val.toLowerCase())) {
        newData.push(item)
        this.setState({
          updatedRegion: newData,
        });
      }
      else {
        this.setState({
          updatedRegion: newData,
        });
      }
    }
    );
    if (e.target.value === '') {
      this.setState({
        updatedRegion: this.props.region,
      });
    }
    this.setState({
      regionValue: val
    })
  }

  searchState = (e) => {
    // debugger
    let stateFilter = this.props.filterState;
    const newData = []
    let val = e.target.value
    const newArray = stateFilter.filter(item => {
      if (item.toLowerCase().includes(val.toLowerCase())) {
        newData.push(item)
        this.setState({
          updatedState: newData,
        });
      }
      else {
        this.setState({
          updatedState: newData,
        });
      }
    }
    );
    if (e.target.value === '') {
      this.setState({
        updatedState: this.props.filterState,
      });
    }
    this.setState({
      stateValue: val
    })
  }
  searchCity = (e) => {

    let cityFilter = this.props.city;
    const newData = [];
    let val = e.target.value;
    const newArray = cityFilter.filter(item => {
      if (item.toLowerCase().includes(val.toLowerCase())) {
        newData.push(item)
        this.setState({
          updatedCity: newData,
        });
      }
      else {
        this.setState({
          updatedCity: newData,
        });
      }
    }
    );
    if (e.target.value === '') {
      this.setState({
        updatedCity: this.props.city,
      });
    }
    this.setState({
      cityValue: val
    })

  }
  searchStore = (e) => {
    // debugger
    // console.log(" this.props.filterStoresData", this.props.filterStoresData)

    let val = e.target.value
    let storeFilter = this.state.updatedStoreFilter;
    const newData = []
    // if (val !== '') {

    const newArray = storeFilter.filter(item => {
      if (item.toLowerCase().includes(val.toLowerCase())) {
        newData.push(item)
        this.setState({
          updatedStore: newData,

        });
      }
      else {
        this.setState({
          updatedStore: newData,

        });
      }
    }
    );

    if (val === '') {
      this.setState({
        updatedStore: this.state.updatedStoreFilter,
      });
    }
    this.setState({
      storeCodeValue: val
    })
    // if (val === '') {
    //   this.setState({
    //     storeCodeValue: val
    //   });
    // }


  }



  onStoreChangeHandler(val) {
    //  debugger
    let storecode = this.state.storeCodeVal;
    if (storecode.includes(val)) {
      const index = storecode.indexOf(val);
      if (index > -1) {
        storecode.splice(index, 1);
      }
    } else {
      storecode.push(val);
    }
    storecode = [...new Set(storecode)];
    this.setState({
      storeCodeVal: storecode,
    });
  }

  // onCloseHandlertwo(){
  //   let val= document.getElementById("regionToggle")
  //   console.log(val)
  //   $(document).click(function(){
  //     $(val).hide();
  //   });

  //   /* Clicks within the dropdown won't make
  //      it past the dropdown itself */
  //   $(val).click(function(e){
  //     e.stopPropagation();
  //   });
  // }
  onCloseHandler(val) {
    if (val.target.value === "closeRegion") {
      this.setState({
        showRegion: false,
        regionName: [],
        onClickedRegion: true,
      });
    }
    if (val.target.value === "closeState") {
      this.setState({
        showState: false,
        stateName: [],
        onClickedState: true,
      });
    }
    if (val.target.value === "closeCity") {
      this.setState({
        showCity: false,
        cityName: [],
        onClickedCity: true,
      });
    }
    if (val.target.value === "closeStore") {
      this.setState({
        show: false,
        storeCodeVal: [],
        onClickedStore: true,
      });
    }
  }
  inputHandler() {
    this.setState({
      // storeCodeVal: [],
      show: !this.state.show,
      showCity: false,
      showRegion: false,
      showState: false,
    });
  }
  render() {
    const { afterToday } = DateRangePicker;
    const {
      changeData,
      filterCity,
      filterRegion,
      filterState,
      storeCodeVal,
      show,
      showRegion,
      regionName,
      showState,
      stateName,
      cityName,
      showCity,
    } = this.state;
    let startD = this.props.commonParmeter.startDate
    let endD = this.props.commonParmeter.endDate
    return (
      <div className="dashboard">
        {this.props.loader && (
          <div className="loader">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
        <div className="page-title">
          <div className="">
            <div className="title">
              <div className="">
                <h3>Home Dashboard</h3>
                <p>
                  Gives you a brief insight into what's going on in your
                  business.
                </p>
              </div>
              <div className="d-flex">
                <div>
                  <DateRangePicker
                    ranges={predefinedRanges}
                    disabledDate={afterToday()}
                    showOneCalendar
                    placement="bottomEnd"
                    // defaultValue={[new Date(startD), new Date(endD)]}
                    onChange={this.setSelectedData}
                    defaultValue={[startOfDay(subDays(new Date(), 29)), endOfDay(new Date())]}
                    onOk={this.setSelectedData}
                  // onClean={this.setSelectedData}
                  />
                </div>
                <img
                  src={filter}
                  className="ml-3"
                  onClick={() => this.handleSetOpen()}
                  alt=""
                />
                <span
                  onClick={() => this.handleSetOpen()}
                  className="filter_cl"
                >
                  Filters
                </span>
              </div>
            </div>
            {/* { this.state.setOpen && */}
            <Collapse in={this.state.open}>
              <div id="example-collapse-text" className="filter-options">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-6 col-md-3">
                      <label>Region</label>
                      <div className="selectBox divmulti-selection addZindex">
                        <input
                          type="text"
                          // disabled={showRegion}
                          className="control"
                          placeholder={
                            this.state.regionValue !== ''
                              ? this.state.regionValue :
                              "Select region"
                          }
                          value={this.state.regionValue}
                          onClick={() =>

                            this.setState({
                              showRegion: !showRegion,
                              showCity: false,
                              showState: false,
                              show: false,
                            })


                          }
                          onChange={this.searchRegion}
                        />
                      </div>

                      {showRegion && (
                        //<div className="divmulti-selection-content divmulti-selection-expanded" id='regionToggle' onClick   = {() => this.onCloseHandlertwo()} >
                        <div
                          className="divmulti-selection-content divmulti-selection-expanded"
                          id="selectCTG"
                        >
                          <div className="col-xs-12 divmulti-selection-inner">
                            {
                            /* {!changeData
                              ? this.props.region.map((val, index) => {
                                */}
                            {

                              this.state.updatedRegion.map((val, index) => {
                                return (
                                  val !== '' &&
                                  <div
                                    className="checkbox-div"
                                    key={index}
                                    value={val}
                                  >
                                    <label>
                                      <input
                                        onClick={() => {
                                          this.onRegionChangeHandler(val);
                                        }}
                                        name="region"
                                        type="checkbox"
                                        value={val}
                                        checked={this.state.regionName.includes(val)? true : false}
                                      />
                                      {val}
                                    </label>
                                  </div>
                                );
                              })
                              // : filterRegion.map((val, index) => {
                              //   return (
                              //     <div
                              //       className="checkbox-div"
                              //       key={index}
                              //       value={val}
                              //     >
                              //       <label>
                              //         <input
                              //           onClick={() => {
                              //             this.onRegionChangeHandler(val);
                              //           }}
                              //           name="region"
                              //           type="checkbox"
                              //           value={val}
                              //         />
                              //         {val}
                              //       </label>
                              //     </div>
                              //   );
                              // })
                            }
                          </div>
                          <div className="col-xs-12 divmulti-selection-inner more-btn-div">
                            <div className="col-xs-12 clearnapply">
                              <div className="btnfilter-selectall">
                                <input
                                  type="checkbox"
                                  id="select-all-region"
                                  onChange={() =>
                                    this.onSelectAllRegionHandler()
                                  }
                                  style={{ marginRight: "4px" }}
                                />
                                <label>Select&nbsp;all</label>
                              </div>
                              <div className="display-inline">
                                <button
                                  className="btn-submit apply mr-1"
                                  style={{ backgroundColor: "#f70c23" }}
                                  value="closeRegion"
                                  onClick={(e) => {
                                    this.onCloseHandler(e);
                                  }}

                                >
                                  Close
                                </button>
                                <button
                                  className="btn-submit clear"
                                  onClick={() => {
                                    this.onCancelRegionHandler();
                                  }}
                                >
                                  Clear
                                </button>
                                {regionName.length ? (
                                  <button
                                    className="btn-submit apply ml-1"
                                    onClick={() => {
                                      this.onApplyRegionHandler();
                                    }}
                                  >
                                    Apply
                                  </button>
                                ) : (
                                  <button
                                    className="btn-submit apply ml-1"
                                    style={{ style: "0.5" }}
                                  >
                                    Apply
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <label>State</label>
                      <div className="selectBox divmulti-selection addZindex">
                        <input
                          type="text"
                          // disabled={showState}
                          className="control"
                          placeholder={
                            this.state.stateValue !== '' ? this.state.stateValue :
                              "Select state"
                          }
                          value={this.state.stateValue}
                          onClick={() =>
                            this.setState({
                              showState: !showState,
                              showCity: false,
                              showRegion: false,
                              show: false,
                            })
                          }
                          onChange={this.searchState}
                        />
                      </div>
                      {showState && (
                        <div
                          className="divmulti-selection-content divmulti-selection-expanded"
                          id="selectCTG"
                        >
                          <div className="col-xs-12 divmulti-selection-inner">
                            {/* {!changeData
                              ? */}
                            {
                              this.state.updatedState.map((val, index) => {
                                return (
                                  val !== '' &&
                                  <div
                                    className="checkbox-div"
                                    key={index}
                                    value={val}
                                  >
                                    <label>
                                      <input
                                        onClick={() => {
                                          this.onStateChangeHandler(val);
                                        }}
                                        name="state"
                                        type="checkbox"
                                        value={val}
                                        checked={this.state.stateName.includes(val)? true : false}
                                      />
                                      {val}
                                    </label>
                                  </div>
                                );
                              })
                              // : filterState.map((val, index) => {
                              //   return (
                              //     <div
                              //       className="checkbox-div"
                              //       key={index}
                              //       value={val}
                              //     >
                              //       <label>
                              //         <input
                              //           onClick={() => {
                              //             this.onStateChangeHandler(val);
                              //           }}
                              //           name="state"
                              //           type="checkbox"
                              //           value={val}
                              //         />
                              //         {val}
                              //       </label>
                              //     </div>
                              //   );
                              // })

                            }
                          </div>
                          <div className="col-xs-12 divmulti-selection-inner more-btn-div">
                            <div className="col-xs-12 clearnapply">
                              <div className="btnfilter-selectall">
                                <input
                                  type="checkbox"
                                  id="select-all-state"
                                  onChange={() =>
                                    this.onSelectAllStateHandler()
                                  }
                                  style={{ marginRight: "4px" }}
                                />
                                <label>Select&nbsp;all</label>
                              </div>
                              <div className="display-inline">
                                <button
                                  className="btn-submit apply mr-1"
                                  style={{ backgroundColor: "#f70c23" }}
                                  value="closeState"
                                  onClick={(e) => {
                                    this.onCloseHandler(e);
                                  }}
                                >
                                  Close
                                </button>
                                <button
                                  className="btn-submit clear"
                                  onClick={() => {
                                    this.onCancelStateHandler();
                                  }}
                                >
                                  Clear
                                </button>
                                {stateName.length ? (
                                  <button
                                    className="btn-submit apply ml-1"
                                    onClick={() => {
                                      this.onApplyStateHandler();
                                    }}
                                  >
                                    Apply
                                  </button>
                                ) : (
                                  <button
                                    className="btn-submit apply ml-1"
                                    style={{ style: "0.5" }}
                                  >
                                    Apply
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <label>City</label>
                      <div className="selectBox divmulti-selection addZindex">
                        <input
                          type="text"
                          // disabled={showCity}
                          className="control"
                          placeholder={
                            this.state.cityValue !== '' ? this.state.cityValue :
                              "Select city"
                          }
                          value={this.state.cityValue}
                          onClick={() =>
                            this.setState({
                              showCity: !showCity,
                              showRegion: false,
                              showState: false,
                              show: false,
                            })
                          }
                          onChange={this.searchCity}
                        />
                      </div>
                      {showCity && (
                        <div
                          className="divmulti-selection-content divmulti-selection-expanded"
                          id="selectCTG"
                        >
                          <div className="col-xs-12 divmulti-selection-inner">
                            {/* {!changeData
                              ?  */}
                            {
                              this.state.updatedCity.map((val, index) => {
                                return (
                                  val !== '' &&
                                  <div
                                    className="checkbox-div"
                                    key={index}
                                    value={val}
                                  >
                                    <label>
                                      <input
                                        onClick={() => {
                                          this.onCityChangeHandler(val);
                                        }}
                                        name="city"
                                        type="checkbox"
                                        value={val}
                                        checked={this.state.cityName.includes(val)? true : false}
                                      />
                                      {val}
                                    </label>
                                  </div>
                                );
                              })
                              // : filterCity.map((val, index) => {
                              //   return (
                              //     <div
                              //       className="checkbox-div"
                              //       key={index}
                              //       value={val}
                              //     >
                              //       <label>
                              //         <input
                              //           onClick={() => {
                              //             this.onCityChangeHandler(val);
                              //           }}
                              //           name="city"
                              //           type="checkbox"
                              //           value={val}
                              //         />
                              //         {val}
                              //       </label>
                              //     </div>
                              //   );
                              // })
                            }
                          </div>
                          <div className="col-xs-12 divmulti-selection-inner more-btn-div">
                            <div className="col-xs-12 clearnapply">
                              <div className="btnfilter-selectall">
                                <input
                                  type="checkbox"
                                  id="select-all-city"
                                  onChange={(val) =>
                                    this.onSelectAllCityHandler(val)
                                  }
                                  style={{ marginRight: "4px" }}
                                />
                                <label>Select&nbsp;all</label>
                              </div>
                              <div className="display-inline">
                                <button
                                  className="btn-submit apply mr-1"
                                  style={{ backgroundColor: "#f70c23" }}
                                  value="closeCity"
                                  onClick={(e) => {
                                    this.onCloseHandler(e);
                                  }}
                                >
                                  Close
                                </button>
                                <button
                                  className="btn-submit clear"
                                  onClick={() => {
                                    this.onCancelCityHandler();
                                  }}
                                >
                                  Clear
                                </button>
                                {cityName.length ? (
                                  <button
                                    className="btn-submit apply ml-1"
                                    onClick={() => {
                                      this.onApplyCityHandler();
                                    }}
                                  >
                                    Apply
                                  </button>
                                ) : (
                                  <button
                                    className="btn-submit apply ml-1"
                                    style={{ style: "0.5" }}
                                  >
                                    Apply
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <label>Store</label>
                      <div className="selectBox divmulti-selection addZindex">
                        <input
                          type="text"
                          // disabled={show}
                          className="control"
                          placeholder={
                            this.state.storeCodeValue !== '' ? this.state.storeCodeValue :
                              "Select store"

                          }
                          value={this.state.storeCodeValue}
                          onClick={() => this.inputHandler()}
                          onChange={this.searchStore}
                        />
                      </div>
                      {show && (
                        <div
                          className="divmulti-selection-content divmulti-selection-expanded"
                          id="selectCTG"
                        >
                          <div className="col-xs-12 divmulti-selection-inner">
                            {/* {!changeData
                              ?  */}
                            {
                              this.state.updatedStore.map(
                                (val, index) => {
                                  return (
                                    val !== '' &&
                                    <div
                                      className="checkbox-div"
                                      key={index}
                                      value={val.store}
                                    >
                                      <label>
                                        <input
                                          onClick={() => {
                                            this.onStoreChangeHandler(
                                              val
                                            );
                                          }}
                                          name="storeCode"
                                          type="checkbox"
                                          value={val}
                                          checked={this.state.storeCodeVal.includes(val)? true : false}
                                        />
                                        {val}
                                      </label>
                                    </div>
                                  );
                                }
                              )
                              // : this.props.storeCode.map((val, index) => {
                              //   return (
                              //     <div
                              //       className="checkbox-div"
                              //       key={index}
                              //       value={val.store}
                              //     >
                              //       <label>
                              //         <input
                              //           onClick={() => {
                              //             this.onStoreChangeHandler(
                              //               val.store
                              //             );
                              //           }}
                              //           name="storeCode"
                              //           type="checkbox"
                              //           value={val.store + val.storeName}
                              //         />
                              //         {val.store + " " + val.storeName}
                              //       </label>
                              //     </div>
                              //   );})

                            }
                          </div>
                          <div className="col-xs-12 divmulti-selection-inner more-btn-div">
                            <div className="col-xs-12 clearnapply">
                              <div className="btnfilter-selectall">
                                <input
                                  type="checkbox"
                                  id="select-all"
                                  onChange={() => this.onSelectAllHandler()}
                                  style={{ marginRight: "4px" }}
                                />
                                <label>Select&nbsp;all</label>
                              </div>
                              <div className="display-inline">
                                <button
                                  className="btn-submit apply mr-1"
                                  style={{ backgroundColor: "#f70c23" }}
                                  value="closeStore"
                                  onClick={(e) => {
                                    this.onCloseHandler(e);
                                  }}
                                >
                                  Close
                                </button>
                                <button
                                  className="btn-submit clear"
                                  onClick={() => {
                                    this.onCancelHandler();
                                  }}
                                >
                                  Clear
                                </button>
                                {storeCodeVal.length ? (
                                  <button
                                    className="btn-submit apply ml-1"
                                    onClick={() => {
                                      this.onApplyHandler();
                                    }}
                                  >
                                    Apply
                                  </button>
                                ) : (
                                  <button
                                    className="btn-submit apply ml-1"
                                    style={{ style: "0.5" }}
                                  >
                                    Apply
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
            {/* } */}
          </div>
        </div>

        <div className="">
          <span style={{ "fontWeight": "bold", "fontSize": "135%" }}>WhatsApp : </span>
          <div className="row">
            {this.state.labels.length === 0
              ? undefined
              : this.state.labels
                .filter((data) => data.isWhatsApp)
                .map((val, index) => {
                  return (
                    <div className="col-sm-3" key={index}>
                      <div className="ui card">
                        <div className="content-height">
                          <div className="header">
                            <span style={{ "fontSize": "1.17em", "fontWeight": "bolder" }}>{val.label}</span>
                            {val.isInfo && val.converted === "" ? (
                              <span className="one-line-outer">
                                <Tooltip title="Unique Customer">
                                  <img
                                    className="info"
                                    src={InfoIcon}
                                    alt="info-icon"
                                  />
                                </Tooltip>
                              </span>
                            ) : null}
                          </div>
                          <span style={{ "fontSize": "1.17em", "fontWeight": "bolder" }}>{val.counts}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
        {/* custom changes for webbot */}
        {/* <div className="">
          <span style={{ "fontWeight": "bold", "fontSize": "135%" }}>WebBot : </span>
          <div className="row">
            {this.state.labels
              .filter(
                (data) => data.isWebbot || data.label === "Payment Received"
              )
              .map((filter, index) => {
                return (
                  <div className="col-sm-3" key={index}>
                    <div className="ui card">
                      <div className="content-height">
                        <div className="header">
                          <span style={{ "fontSize": "1.17em", "fontWeight": "bolder" }}>{filter.label}</span>
                          {filter.isInfo && filter.converted !== "" ? (
                            <span className="one-line-outer">
                              <Tooltip
                                title={
                                  filter.label ===
                                    "Landed On WebBot"
                                    ? "Total No of Customers Landed On WebBot"
                                    : filter.label ===
                                      "Add To Cart On WebBot"
                                      ? "Unique Customer"
                                      : filter.label ===
                                        "Payment Received"
                                        ? "Away from Bot"
                                        : filter.label ===
                                          "Order Place CTA On WebBot"
                                          ? "Customers who have Successfully added the Address for Product Delivery"
                                          : null
                                }
                              >
                                <img
                                  className="info"
                                  src={InfoIcon}
                                  alt="info-icon"
                                />
                              </Tooltip>
                              <TiArrowSortedUp className="info-up" />
                              {filter.converted}&nbsp;%
                              <TiArrowSortedDown className="info-down" />
                              {filter.drop}&nbsp;%
                            </span>
                          ) : filter.isInfo && filter.converted === "" ? (
                            <span className="one-line-outer">
                              <Tooltip title="Unique Customer">
                                <img
                                  className="info"
                                  src={InfoIcon}
                                  alt="info-icon"
                                />
                              </Tooltip>
                            </span>
                          ) : filter.converted !== "" ? (
                            <span className="one-line-outer">
                              <TiArrowSortedUp className="info-up" />
                              {filter.converted}&nbsp;%
                              <TiArrowSortedDown className="info-down" />
                              {filter.drop}&nbsp;%
                            </span>
                          ) : null}
                        </div>
                        <span style={{ "fontSize": "1.17em", "fontWeight": "bolder" }}>{filter.counts}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
         */}
        {/* <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="customized table">
            <TableHead>
              <TableRow
                style={{
                  align: "center",
                  backgroundColor: "rgb(194 217 243)",
                }}
              >
                <TableCell>Funnel Stage</TableCell>
                <TableCell>Counts</TableCell>
                <TableCell>
                  Conversion &nbsp;
                  <TiArrowSortedUp className="info-up" />
                </TableCell>
                <TableCell>
                  Drop&nbsp; <TiArrowSortedDown className="info-down" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
             
              {this.state.labels !== null &&
                this.state.labels
                  .filter(
                    (x) =>
                      x.converted !== "" || x.label === "Landed On WhatsApp"
                  )
                  .map((filter, i) => (
                    <TableRow key={i}>
                      <React.Fragment>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ align: "center" }}
                        >
                          {filter.label}
                        </TableCell>
                        <TableCell>{filter.counts}</TableCell>
                        {filter.converted !== "" ? (
                          <React.Fragment>
                            <TableCell>{filter.converted}&nbsp;%</TableCell>
                            <TableCell>{filter.drop}&nbsp;%</TableCell>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <TableCell>N/A</TableCell>
                            <TableCell>N/A</TableCell>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                     
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer> 
 */}


        <div className="">
          <div className="row">
            <div className="col-md-9">
              <div className="ui card">
                <div className="content">
                  <div className="header">
                    <h3>Campaign Overview</h3>
                  </div>
                  <div className="description placebox">
                    {
                      <TopPerformance
                        onRef={(ref) => (this.topperformchart = ref)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="ui card">
                <div className="content">
                  <div className="header">
                    <h3>Campaign Source</h3>
                  </div>
                  {/* <div class="meta"><span class="date">Joined in 2015</span></div> */}
                  <div className="description">
                    <PieChart onRef={(ref) => (this.piechart = ref)} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="ui card">
                <div className="content">
                  <CampaignPerformance
                    onRef={(ref) => (this.campaignchart = ref)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="ui card">
                <div className="content">
                  <ProductPerformance
                    onRef={(ref) => (this.productchart = ref)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="ui card">
                <div className="content">
                  <BarChart onRef={(ref) => (this.barchart = ref)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    commonParmeter: state.commonParmeter,
    selfPercent: state.selfPercent,
    loader: state.loader,
    filterStoresData: state.filterStoresData,
    region: state.region,
    filterState: state.filterState,
    city: state.city,
    storeCode: state.storeCode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCommonParmeter: (obj) => {
      dispatch({ type: "GET_COMMON_PARMETER", payload: obj });
    },
    getFilterStoredetails: (store) => {
      dispatch({ type: "FILTER_STORE_DATA", payload: store });
    },
    getFilterRegionDetails: (region) => {
      dispatch({ type: "REGION_DATA", payload: region });
    },
    getFilterStateDetails: (state) => {
      dispatch({ type: "STATE_DATA", payload: state });
    },
    getFilterCityDetails: (city) => {
      dispatch({ type: "CITY_DATA", payload: city });
    },
    getFilterStoreCodeDetails: (storeCode) => {
      dispatch({ type: "STORE_DATA", payload: storeCode });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

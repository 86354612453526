import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import download from "../../../assets/images/icons/download.png";
import filter from "../../../assets/images/icons/filter.svg";
import Collapse from "react-bootstrap/Collapse";
import { connect } from "react-redux";
import { commonService } from "../../../Utils/common.service";
import { CSVLink } from "react-csv";
import { Spinner } from "react-bootstrap";
import { endOfDay, format, startOfDay } from "date-fns";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import { Pagination as Pagination1 } from "semantic-ui-react";
import report_download from "../../../assets/images/icons/report_download.svg";
import ReportBulkDownload from "../../view/ReportBulkDownload";
import DataNotFoundImg from "../../../assets/images/widget-no-data.svg";
import { handleMaskingNumber } from "../../mobileMasking";
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import moment from 'moment'

const predefinedRanges = [
  {
    label: 'Last 7 days',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left'
  },
  {
    label: 'Last month',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left'
  },
  {
    label: 'Last 6 month',
    value: [startOfMonth(addMonths(new Date(), -6)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left'
  }
];

class VideoCallSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      bellData: false,
      dateRangeOpen: false,
      activePage: 1,
      totalpost: 0,
      postperPage: 10,
      videoCallDetails: [],
      videoCallDetailsdata: [],
      loader: true,
      filterState: [],
      filterCity: [],
      filterRegion: [],
      filterStoreCode: [],
      changeData: false,
      storeCodeVal: [],
      show: false,
      showCity: false,
      showState: false,
      stateName: [],
      showRegion: false,
      regionName: [],
      cityName: [],
      count: false,
      onClickedCity: true,
      onClickedRegion: true,
      onClickedState: true,
      onClickedStore: true,
      report: false,
      isNumberMasking: false,
      maskingPrefix: 0,
      maskingSufix: 0,
    };
  }

  paginate(pageNumber) {
    this.setState({
      activePage: pageNumber,
    });
  }
  handleSetOpen() {
    this.setState({
      open: !this.state.open,
      report: false,
    });
  }
  handleReportDownload() {
    this.setState({
      report: !this.state.report,
      open: false,
    });
  }

  componentDidMount() {
    let data = this.props.commonParmeter
    data.startDate = moment().subtract(29, "days").format("MM/DD/YYYY")
    data.endDate = moment().format("MM/DD/YYYY")
    data.storeCode = [];
    this.props.getCommonParmeter(data);
    this.videoCallDetailsApi();
  }

  videoCallDetailsApi() {
    let data = this.props.commonParmeter;
    let programCode = window.sessionStorage.getItem("programCode");
    if (programCode !== null) {
      commonService
        .VideoCallSummary(
          data.storeCode,
          data.startDate,
          data.endDate,
          programCode
        )
        .then((response) => {
          if (response.isSuccess === "true") {
            this.setState({
              loader: false,
            });
            for (let i = 0; i < response.data.length; i++) {
              response.data[i].appointmentDate = this.convertDisplayDate(
                response.data[i].appointmentDate
              );
            }
            this.setState({
              videoCallDetails: response.data,
              isNumberMasking:
                window.localStorage.getItem("isNumberMasking") === "true"
                  ? true
                  : false,
              maskingPrefix: window.localStorage.getItem("mobileMaskingPrefix"),
              maskingSufix: window.localStorage.getItem("mobileMaskingSufix"),
              totalpost: response.data.length,
            });
            this.props.getVideoCallSummary(this.state.videoCallDetails);
          } else {
            //  alert('No Record Found!!')
            this.setState({
              loader: false,
              videoCallDetails: [],
              totalpost: 0,
            });
            this.props.getVideoCallSummary([]);
          }
        });
    } else {
      this.props.history.push("/");
    }
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  datePickHandle() {
    this.setState({
      open: false,
      report: false,
    });
  }
  convertDisplayDate(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  changeDateHandler = (val) => {
    let data = this.props.commonParmeter;
    data.startDate = format(new Date(val[0]), "MM/dd/yyyy");
    data.endDate = format(new Date(val[1]), "MM/dd/yyyy");
    console.log("data", data);
    if (data.startDate !== "" && data.endDate !== "") {
      this.props.getCommonParmeter(data);
      this.videoCallDetailsApi();
    }
    // }
  };

  emptyCsvDataFile = () => {
    this.setState({
      videoCallDetailsdata: [],
    });
  };

  downloadfileBtn(storecode, index) {
    this.setState({
      loader: true,
    });
    let storeCode = storecode;
    let data = this.props.commonParmeter;
    let programCode = window.sessionStorage.getItem("programCode");
    commonService
      .VideoCallSummary(storeCode, data.startDate, data.endDate, programCode)
      .then((response) => {
        if (response.isSuccess === "true") {
          this.setState({
            loader: false,
            videoCallDetailsdata: response.data,
            downloadIndex: index,
            bellData: false,
          });
        } else {
          this.setState({
            loader: false,
            bellData: true,
          });
        }
      });
  }

  onCancelHandler() {
    var items = document.getElementsByName("storeCode");
    for (var i = 0; i < items.length; i++) {
      if (items[i].type === "checkbox") {
        items[i].checked = false;
      }
    }
    this.setState({
      storeCodeVal: [],
    });
  }

  onCancelCityHandler() {
    var items = document.getElementsByName("city");
    for (var i = 0; i < items.length; i++) {
      if (items[i].type === "checkbox") {
        items[i].checked = false;
      }
    }
    this.setState({
      cityName: [],
    });
  }

  onCancelStateHandler() {
    var items = document.getElementsByName("state");
    for (var i = 0; i < items.length; i++) {
      if (items[i].type === "checkbox") {
        items[i].checked = false;
      }
    }
    this.setState({
      stateName: [],
    });
  }
  onCancelRegionHandler() {
    var items = document.getElementsByName("region");
    for (var i = 0; i < items.length; i++) {
      if (items[i].type === "checkbox") {
        items[i].checked = false;
      }
    }
    this.setState({
      regionName: [],
    });
  }

  onSelectAllHandler() {
    document.getElementById("select-all").onchange = function () {
      var checkboxes = document.getElementsByName("storeCode");
      for (var checkbox of checkboxes) {
        checkbox.checked = this.checked;
      }
    };
    var clicked = this.state.onClickedStore;
    if (clicked === true) {
      let store = [];
      if (this.state.changeData === true) {
        let selectedData = this.props.storeCode;
        for (let i = 0; i < selectedData.length; i++) {
          store.push(selectedData[i].store);
        }
      } else {
        let selectedData = this.props.filterStoresData;
        for (let i = 0; i < selectedData.length; i++) {
          store.push(selectedData[i].store);
        }
      }
      this.setState({
        storeCodeVal: store.flat(),
        onClickedStore: false,
      });
    }
    if (clicked === false) {
      this.setState({
        storeCodeVal: [],
        onClickedStore: true,
      });
    }
  }

  onSelectAllCityHandler(val) {
    document.getElementById("select-all-city").onchange = function () {
      var checkboxes = document.getElementsByName("city");
      for (var checkbox of checkboxes) {
        checkbox.checked = this.checked;
      }
    };
    var clicked = this.state.onClickedCity;
    if (clicked === true) {
      let data = this.state.cityName;
      if (this.state.changeData === true) {
        data.push(this.state.filterCity);
      } else {
        data.push(this.props.city);
      }
      this.setState({
        onClickedCity: false,
        cityName: data.flat(),
      });
    }
    if (clicked === false) {
      this.setState({
        cityName: [],
        onClickedCity: true,
      });
    }
  }

  onSelectAllStateHandler() {
    document.getElementById("select-all-state").onchange = function () {
      var checkboxes = document.getElementsByName("state");
      for (var checkbox of checkboxes) {
        checkbox.checked = this.checked;
      }
    };
    var clicked = this.state.onClickedState;
    if (clicked === true) {
      let data = this.state.stateName;
      if (this.state.changeData === true) {
        data.push(this.state.filterState);
      } else {
        data.push(this.props.filterState);
      }
      this.setState({
        onClickedState: false,
        stateName: data.flat(),
      });
    }
    if (clicked === false) {
      this.setState({
        stateName: [],
        onClickedState: true,
      });
    }
  }

  onSelectAllRegionHandler() {
    document.getElementById("select-all-region").onchange = function () {
      var checkboxes = document.getElementsByName("region");
      for (var checkbox of checkboxes) {
        checkbox.checked = this.checked;
      }
    };
    var clicked = this.state.onClickedRegion;
    if (clicked === true) {
      let data = this.state.regionName;
      if (this.state.changeData === true) {
        data.push(this.state.filterRegion);
      } else {
        data.push(this.props.region);
      }
      this.setState({
        onClickedRegion: false,
        regionName: data.flat(),
      });
    }
    if (clicked === false) {
      this.setState({
        regionName: [],
        onClickedRegion: true,
      });
    }
  }

  onApplyHandler() {
    if (this.state.storeCodeVal !== null) {
      let commonParmeter = this.props.commonParmeter;
      commonParmeter.storeCode = this.state.storeCodeVal;
      this.props.getCommonParmeter(commonParmeter);
      this.videoCallDetailsApi();
      this.setState({
        show: !this.state.show,
        // cityName: [],
        // stateName: [],
        // regionName: [],
        // storeCodeVal: [],
        changeData: false,
        onClickedStore: true,
      });
    }
  }

  onApplyCityHandler() {
    if (this.state.cityName !== null) {
      let programcode = this.props.filterStoresData;
      let data = this.state.cityName;
      let selectedData = [];
      for (let i = 0; i < data.length; i++) {
        selectedData.push(programcode.filter((obj) => obj.city === data[i]));
      }
      this.props.getFilterStoreCodeDetails(selectedData.flat());
      var filteredData = selectedData.flat();
      let region = [];
      let state = [];
      let city = [];
      for (let i = 0; i < filteredData.length; i++) {
        region.push(filteredData[i].region);
        state.push(filteredData[i].state);
        city.push(filteredData[i].city);
        region = [...new Set(region)].sort();
        state = [...new Set(state)].sort();
        city = [...new Set(city)].sort();
      }
      this.setState({
        showCity: false,
        changeData: true,
        filterRegion: region,
        filterState: state,
        filterCity: city,
        onClickedCity: true,
      });
    }
  }

  onApplyStateHandler() {
    if (this.state.stateName !== null) {
      let programcode = this.props.filterStoresData;
      let data = this.state.stateName;
      let selectedData = [];
      for (let i = 0; i < data.length; i++) {
        selectedData.push(programcode.filter((obj) => obj.state === data[i]));
      }
      this.props.getFilterStoreCodeDetails(selectedData.flat());
      var filteredData = selectedData.flat();
      let region = [];
      let state = [];
      let city = [];
      for (let i = 0; i < filteredData.length; i++) {
        region.push(filteredData[i].region);
        state.push(filteredData[i].state);
        city.push(filteredData[i].city);
        region = [...new Set(region)].sort();
        state = [...new Set(state)].sort();
        city = [...new Set(city)].sort();
      }
      this.setState({
        showState: false,
        changeData: true,
        filterRegion: region,
        filterState: state,
        filterCity: city,
        onClickedState: true,
      });
    }
  }

  onApplyRegionHandler() {
    if (this.state.regionName !== null) {
      let programcode = this.props.filterStoresData;
      let data = this.state.regionName;
      let selectedData = [];
      for (let i = 0; i < data.length; i++) {
        selectedData.push(programcode.filter((obj) => obj.region === data[i]));
      }
      //console.log(selectedData.flat())
      this.props.getFilterStoreCodeDetails(selectedData.flat());
      var filteredData = selectedData.flat();
      let region = [];
      let state = [];
      let city = [];
      for (let i = 0; i < filteredData.length; i++) {
        region.push(filteredData[i].region);
        state.push(filteredData[i].state);
        city.push(filteredData[i].city);
        region = [...new Set(region)].sort();
        state = [...new Set(state)].sort();
        city = [...new Set(city)].sort();
      }
      this.setState({
        showRegion: false,
        changeData: true,
        filterRegion: region,
        filterState: state,
        filterCity: city,
        onClickedRegion: true,
      });
    }
  }

  onCityChangeHandler(val) {
    // console.log(this.state.cityName)
    let city = this.state.cityName;
    if (city.includes(val)) {
      const index = city.indexOf(val);
      if (index > -1) {
        city.splice(index, 1);
      }
    } else {
      city.push(val);
    }
    city = [...new Set(city)].sort();
    this.setState({
      cityName: city,
      storeCodeVal: [],
    });
  }

  onStateChangeHandler(val) {
    let state = this.state.stateName;
    if (state.includes(val)) {
      const index = state.indexOf(val);
      if (index > -1) {
        state.splice(index, 1);
      }
    } else {
      state.push(val);
    }
    state = [...new Set(state)].sort();
    this.setState({
      stateName: state,
      storeCodeVal: [],
      cityName: [],
    });
  }

  onRegionChangeHandler(val) {
    let region = this.state.regionName;
    if (region.includes(val)) {
      const index = region.indexOf(val);
      if (index > -1) {
        region.splice(index, 1);
      }
    } else {
      region.push(val);
    }
    region = [...new Set(region)].sort();
    this.setState({
      regionName: region,
      storeCodeVal: [],
      cityName: [],
      stateName: [],
    });
  }

  onStoreChangeHandler(val) {
    let storecode = this.state.storeCodeVal;
    if (storecode.includes(val)) {
      const index = storecode.indexOf(val);
      if (index > -1) {
        storecode.splice(index, 1);
      }
    } else {
      storecode.push(val);
    }
    storecode = [...new Set(storecode)];
    this.setState({
      storeCodeVal: storecode,
    });
  }

  onCloseHandler(val) {
    if (val.target.value === "closeRegion") {
      this.setState({
        showRegion: false,
        regionName: [],
        onClickedRegion: true,
      });
    }
    if (val.target.value === "closeState") {
      this.setState({
        showState: false,
        stateName: [],
        onClickedState: true,
      });
    }
    if (val.target.value === "closeCity") {
      this.setState({
        showCity: false,
        cityName: [],
        onClickedCity: true,
      });
    }
    if (val.target.value === "closeStore") {
      this.setState({
        show: false,
        storeCodeVal: [],
        onClickedStore: true,
      });
    }
  }
  render() {
    const { afterToday } = DateRangePicker;
    const {
      open,
      videoCallDetailsdata,
      activePage,
      totalpost,
      postperPage,
      loader,
      changeData,
      storeCodeVal,
      show,
      showState,
      showRegion,
      showCity,
      cityName,
      stateName,
      regionName,
      filterState,
      filterRegion,
      filterCity,
      report,
    } = this.state;
    const pageNumbers = [];
    const indexOfLastPost = activePage * postperPage;
    const indexOfFirstPost = indexOfLastPost - postperPage;
    let currentPosts = null;
    // eslint-disable-next-line
    {
      currentPosts =
        this.props.reportVideoCallSummary != null
          ? this.props.reportVideoCallSummary.slice(
              indexOfFirstPost,
              indexOfLastPost
            )
          : this.props.reportVideoCallSummary;
    }

    for (let index = 1; index <= Math.ceil(totalpost / postperPage); index++) {
      pageNumbers.push(index);
    }
    let startD = this.props.commonParmeter.startDate
    let endD = this.props.commonParmeter.endDate
    return (
      <>
        {loader && (
          <div className="loader">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
        <div className="reports campaign">
          <div className="page-title">
            <div className="">
              <div className="title">
                <div className="">
                  <h3>VideoCall Details</h3>
                  <p>Reports</p>
                </div>
                <div className="d-flex date-range-picker">
                  <img
                    src={report_download}
                    // className="ml-3"
                    onClick={() => this.handleReportDownload()}
                    title="Download Reports"
                    alt=""
                  />
                  <div>
                    <DateRangePicker
                      ranges={predefinedRanges}
                      disabledDate={afterToday()}
                      showOneCalendar
                      placement="bottomEnd"
                      onOk={this.changeDateHandler}
                      onChange={this.changeDateHandler}
                      defaultValue={[startOfDay(subDays(new Date(), 29)), endOfDay(new Date())]}
                    />
                  </div>
                  <img
                    src={filter}
                    className="ml-3"
                    onClick={() => this.handleSetOpen()}
                    alt=""
                  />
                  <span
                    onClick={() => this.handleSetOpen()}
                    className="filter_cl"
                  >
                    Filters
                  </span>
                </div>
              </div>
              <Collapse in={open}>
                <div id="example-collapse-text" className="filter-options">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-sm-6 col-md-3">
                        <label>Region</label>
                        <div className="selectBox divmulti-selection addZindex">
                          <input
                            type="text"
                            disabled={showRegion}
                            className="control"
                            placeholder={
                              regionName.length
                                ? regionName.join()
                                : "Select region"
                            }
                            onClick={() =>
                              this.setState({
                                showRegion: !showRegion,
                                showCity: false,
                                showState: false,
                                show: false,
                              })
                            }
                          />
                        </div>
                        {showRegion && (
                          <div
                            className="divmulti-selection-content divmulti-selection-expanded"
                            id="selectCTG"
                          >
                            <div className="col-xs-12 divmulti-selection-inner">
                              {!changeData
                                ? this.props.region.map((val, index) => {
                                    return (
                                      <div
                                        className="checkbox-div"
                                        key={index}
                                        value={val}
                                      >
                                        <label>
                                          <input
                                            onClick={() => {
                                              this.onRegionChangeHandler(val);
                                            }}
                                            name="region"
                                            type="checkbox"
                                            value={val}
                                          />
                                          {val}
                                        </label>
                                      </div>
                                    );
                                  })
                                : filterRegion.map((val, index) => {
                                    return (
                                      <div
                                        className="checkbox-div"
                                        key={index}
                                        value={val}
                                      >
                                        <label>
                                          <input
                                            onClick={() => {
                                              this.onRegionChangeHandler(val);
                                            }}
                                            name="region"
                                            type="checkbox"
                                            value={val}
                                          />
                                          {val}
                                        </label>
                                      </div>
                                    );
                                  })}
                            </div>
                            <div className="col-xs-12 divmulti-selection-inner more-btn-div">
                              <div className="col-xs-12 clearnapply">
                                <div className="btnfilter-selectall">
                                  <input
                                    type="checkbox"
                                    id="select-all-region"
                                    onChange={() =>
                                      this.onSelectAllRegionHandler()
                                    }
                                    style={{ marginRight: "4px" }}
                                  />
                                  <label>Select&nbsp;all</label>
                                </div>
                                <div className="display-inline">
                                  <button
                                    className="btn-submit apply mr-1"
                                    style={{ backgroundColor: "#f70c23" }}
                                    value="closeRegion"
                                    onClick={(e) => {
                                      this.onCloseHandler(e);
                                    }}
                                  >
                                    Close
                                  </button>
                                  <button
                                    className="btn-submit clear"
                                    onClick={() => {
                                      this.onCancelRegionHandler();
                                    }}
                                  >
                                    Clear
                                  </button>
                                  {regionName.length ? (
                                    <button
                                      className="btn-submit apply ml-1"
                                      onClick={() => {
                                        this.onApplyRegionHandler();
                                      }}
                                    >
                                      Apply
                                    </button>
                                  ) : (
                                    <button
                                      className="btn-submit apply ml-1"
                                      style={{ style: "0.5" }}
                                    >
                                      Apply
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <label>State</label>
                        <div className="selectBox divmulti-selection addZindex">
                          <input
                            type="text"
                            disabled={showState}
                            className="control"
                            placeholder={
                              stateName.length
                                ? stateName.join()
                                : "Select store"
                            }
                            onClick={() =>
                              this.setState({
                                showState: !showState,
                                showCity: false,
                                showRegion: false,
                                show: false,
                              })
                            }
                          />
                        </div>
                        {showState && (
                          <div
                            className="divmulti-selection-content divmulti-selection-expanded"
                            id="selectCTG"
                          >
                            <div className="col-xs-12 divmulti-selection-inner">
                              {!changeData
                                ? this.props.filterState.map((val, index) => {
                                    return (
                                      <div
                                        className="checkbox-div"
                                        key={index}
                                        value={val}
                                      >
                                        <label>
                                          <input
                                            onClick={() => {
                                              this.onStateChangeHandler(val);
                                            }}
                                            name="state"
                                            type="checkbox"
                                            value={val}
                                          />
                                          {val}
                                        </label>
                                      </div>
                                    );
                                  })
                                : filterState.map((val, index) => {
                                    return (
                                      <div
                                        className="checkbox-div"
                                        key={index}
                                        value={val}
                                      >
                                        <label>
                                          <input
                                            onClick={() => {
                                              this.onStateChangeHandler(val);
                                            }}
                                            name="state"
                                            type="checkbox"
                                            value={val}
                                          />
                                          {val}
                                        </label>
                                      </div>
                                    );
                                  })}
                            </div>
                            <div className="col-xs-12 divmulti-selection-inner more-btn-div">
                              <div className="col-xs-12 clearnapply">
                                <div className="btnfilter-selectall">
                                  <input
                                    type="checkbox"
                                    id="select-all-state"
                                    onChange={() =>
                                      this.onSelectAllStateHandler()
                                    }
                                    style={{ marginRight: "4px" }}
                                  />
                                  <label>Select&nbsp;all</label>
                                </div>
                                <div className="display-inline">
                                  <button
                                    className="btn-submit apply mr-1"
                                    style={{ backgroundColor: "#f70c23" }}
                                    value="closeState"
                                    onClick={(e) => {
                                      this.onCloseHandler(e);
                                    }}
                                  >
                                    Close
                                  </button>
                                  <button
                                    className="btn-submit clear"
                                    onClick={() => {
                                      this.onCancelStateHandler();
                                    }}
                                  >
                                    Clear
                                  </button>
                                  {stateName.length ? (
                                    <button
                                      className="btn-submit apply ml-1"
                                      onClick={() => {
                                        this.onApplyStateHandler();
                                      }}
                                    >
                                      Apply
                                    </button>
                                  ) : (
                                    <button
                                      className="btn-submit apply ml-1"
                                      style={{ style: "0.5" }}
                                    >
                                      Apply
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <label>City</label>
                        <div className="selectBox divmulti-selection addZindex">
                          <input
                            type="text"
                            disabled={showCity}
                            className="control"
                            placeholder={
                              cityName.length ? cityName.join() : "Select city"
                            }
                            onClick={() =>
                              this.setState({
                                showCity: !showCity,
                                showRegion: false,
                                showState: false,
                                show: false,
                              })
                            }
                          />
                        </div>
                        {showCity && (
                          <div
                            className="divmulti-selection-content divmulti-selection-expanded"
                            id="selectCTG"
                          >
                            <div className="col-xs-12 divmulti-selection-inner">
                              {!changeData
                                ? this.props.city.map((val, index) => {
                                    return (
                                      <div
                                        className="checkbox-div"
                                        key={index}
                                        value={val}
                                      >
                                        <label>
                                          <input
                                            onClick={() => {
                                              this.onCityChangeHandler(val);
                                            }}
                                            name="city"
                                            type="checkbox"
                                            value={val}
                                          />
                                          {val}
                                        </label>
                                      </div>
                                    );
                                  })
                                : filterCity.map((val, index) => {
                                    return (
                                      <div
                                        className="checkbox-div"
                                        key={index}
                                        value={val}
                                      >
                                        <label>
                                          <input
                                            onClick={() => {
                                              this.onCityChangeHandler(val);
                                            }}
                                            name="city"
                                            type="checkbox"
                                            value={val}
                                          />
                                          {val}
                                        </label>
                                      </div>
                                    );
                                  })}
                            </div>
                            <div className="col-xs-12 divmulti-selection-inner more-btn-div">
                              <div className="col-xs-12 clearnapply">
                                <div className="btnfilter-selectall">
                                  <input
                                    type="checkbox"
                                    id="select-all-city"
                                    onChange={(val) =>
                                      this.onSelectAllCityHandler(val)
                                    }
                                    style={{ marginRight: "4px" }}
                                  />
                                  <label>Select&nbsp;all</label>
                                </div>
                                <div className="display-inline">
                                  <button
                                    className="btn-submit apply mr-1"
                                    style={{ backgroundColor: "#f70c23" }}
                                    value="closeCity"
                                    onClick={(e) => {
                                      this.onCloseHandler(e);
                                    }}
                                  >
                                    Close
                                  </button>
                                  <button
                                    className="btn-submit clear"
                                    onClick={() => {
                                      this.onCancelCityHandler();
                                    }}
                                  >
                                    Clear
                                  </button>
                                  {cityName.length ? (
                                    <button
                                      className="btn-submit apply ml-1"
                                      onClick={() => {
                                        this.onApplyCityHandler();
                                      }}
                                    >
                                      Apply
                                    </button>
                                  ) : (
                                    <button
                                      className="btn-submit apply ml-1"
                                      style={{ style: "0.5" }}
                                    >
                                      Apply
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <label>Store</label>
                        <div className="selectBox divmulti-selection addZindex">
                          <input
                            type="text"
                            disabled={show}
                            className="control"
                            placeholder={
                              storeCodeVal.length
                                ? storeCodeVal.join()
                                : "Select store"
                            }
                            onClick={() =>
                              this.setState({
                                show: !show,
                                showCity: false,
                                showRegion: false,
                                showState: false,
                              })
                            }
                          />
                        </div>
                        {show && (
                          <div
                            className="divmulti-selection-content divmulti-selection-expanded"
                            id="selectCTG"
                          >
                            <div className="col-xs-12 divmulti-selection-inner">
                              {!changeData
                                ? this.props.filterStoresData.map(
                                    (val, index) => {
                                      return (
                                        <div
                                          className="checkbox-div"
                                          key={index}
                                          value={val.store}
                                        >
                                          <label>
                                            <input
                                              onClick={() => {
                                                this.onStoreChangeHandler(
                                                  val.store
                                                );
                                              }}
                                              name="storeCode"
                                              type="checkbox"
                                              value={val.store}
                                            />{" "}
                                            {val.store}
                                          </label>
                                        </div>
                                      );
                                    }
                                  )
                                : this.props.storeCode.map((val, index) => {
                                    return (
                                      <div
                                        className="checkbox-div"
                                        key={index}
                                        value={val.store}
                                      >
                                        <label>
                                          <input
                                            onClick={() => {
                                              this.onStoreChangeHandler(
                                                val.store
                                              );
                                            }}
                                            name="storeCode"
                                            type="checkbox"
                                            value={val.store}
                                          />
                                          {val.store}
                                        </label>
                                      </div>
                                    );
                                  })}
                            </div>
                            <div className="col-xs-12 divmulti-selection-inner more-btn-div">
                              <div className="col-xs-12 clearnapply">
                                <div className="btnfilter-selectall">
                                  <input
                                    type="checkbox"
                                    id="select-all"
                                    onChange={() => this.onSelectAllHandler()}
                                    style={{ marginRight: "4px" }}
                                  />
                                  <label>Select&nbsp;all</label>
                                </div>
                                <div className="display-inline">
                                  <button
                                    className="btn-submit apply mr-1"
                                    style={{ backgroundColor: "#f70c23" }}
                                    value="closeStore"
                                    onClick={(e) => {
                                      this.onCloseHandler(e);
                                    }}
                                  >
                                    Close
                                  </button>
                                  <button
                                    className="btn-submit clear"
                                    onClick={() => {
                                      this.onCancelHandler();
                                    }}
                                  >
                                    Clear
                                  </button>
                                  {storeCodeVal.length ? (
                                    <button
                                      className="btn-submit apply ml-1"
                                      onClick={() => {
                                        this.onApplyHandler();
                                      }}
                                    >
                                      Apply
                                    </button>
                                  ) : (
                                    <button
                                      className="btn-submit apply ml-1"
                                      style={{ style: "0.5" }}
                                    >
                                      Apply
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
          {report ? (
            <ReportBulkDownload />
          ) : (
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Appointment Id</Table.HeaderCell>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Time</Table.HeaderCell>
                  <Table.HeaderCell>CustomerInfo</Table.HeaderCell>
                  <Table.HeaderCell>Notes</Table.HeaderCell>
                  <Table.HeaderCell>Store</Table.HeaderCell>
                  <Table.HeaderCell>StaffName</Table.HeaderCell>
                  <Table.HeaderCell>Comments</Table.HeaderCell>
                  <Table.HeaderCell>Action </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {currentPosts.length ? (
                  currentPosts.map((ele, index) => {
                    return (
                      <Table.Row key={index}>
                        <Table.Cell>
                          <h4>{ele.appointmentID}</h4>
                          {/* <small>2020</small> */}
                        </Table.Cell>
                        <Table.Cell>
                          <h4>{ele.appointmentDate}</h4>
                          {/* <small>{ele.storeName}</small> */}
                        </Table.Cell>
                        <Table.Cell>
                          <h4>{ele.time}</h4>
                        </Table.Cell>
                        <Table.Cell>
                          <h4>{ele.customerName}<h7>&</h7></h4>
                          {this.state.isNumberMasking === true ? (
                            <h8>
                              {handleMaskingNumber(
                                ele.customerMobileNo,
                                this.state.maskingPrefix,
                                this.state.maskingSufix
                              )}
                            </h8>
                          ) : (
                            <h8>{ele.customerMobileNo}</h8>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          <h4>{ele.customerNote}</h4>
                        </Table.Cell>
                        <Table.Cell>
                          <h4>{ele.storeCode}</h4>
                        </Table.Cell>
                        <Table.Cell>
                          <h4>{ele.storeStaffName}</h4>
                        </Table.Cell>
                        <Table.Cell>
                          <h4>{ele.stroeStaffComment}</h4>
                        </Table.Cell>
                        <Table.Cell>
                          {videoCallDetailsdata.length &&
                          videoCallDetailsdata !== null &&
                          this.state.downloadIndex === index &&
                          !this.state.bellData ? (
                            <CSVLink
                              data={
                                videoCallDetailsdata.length
                                  ? videoCallDetailsdata
                                  : "No Record Found"
                              }
                              filename={
                                "videoCallDetailsdata_" + ele.storeCode + ".csv"
                              }
                            >
                              <button
                                className="table-down"
                                onClick={this.emptyCsvDataFile}
                              >
                                <img src={download} alt="" width="20px" />
                              </button>
                              <small>Download</small>
                            </CSVLink>
                          ) : (
                            <>
                              <button
                                className="table-down"
                                onClick={() => {
                                  this.downloadfileBtn(ele.storeCode, index);
                                }}
                              >
                                <img src={download} alt="" width="20px" />
                              </button>
                              <small>Proceed</small>
                            </>
                          )}
                          {this.state.bellData && (
                            <p className="text-danger mt-1">No Record Found</p>
                          )}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell>
                      <img className="imagesize" alt="" src={DataNotFoundImg} />
                      <p className="text-danger">No Record Found !!</p>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>

              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="12">
                    {/* <Menu  pagination> */}
                    <ul className="pagination-div">
                      {pageNumbers.length > 1 ? (
                        <Pagination1
                          boundaryRange={0}
                          defaultActivePage={1}
                          ellipsisItem={null}
                          prevItem={
                            pageNumbers.length > 1 &&
                            this.state.activePage !== 1
                              ? "Prev"
                              : null
                          }
                          nextItem={
                            pageNumbers.length > 1 &&
                            this.state.activePage !== pageNumbers.length
                              ? "Next"
                              : null
                          }
                          pointing
                          //secondary
                          firstItem={
                            pageNumbers.length >= 10 &&
                            this.state.activePage !== 1
                              ? "First Page"
                              : null
                          }
                          lastItem={
                            pageNumbers.length >= 10 &&
                            this.state.activePage !== pageNumbers.length
                              ? "Last Page"
                              : null
                          }
                          totalPages={pageNumbers.length}
                          siblingRange={4}
                          onPageChange={(event, data) =>
                            this.paginate(data.activePage)
                          }
                        />
                      ) : null}
                    </ul>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commonParmeter: state.commonParmeter,
    reportVideoCallSummary: state.reportVideoCallSummary,
    filterStoresData: state.filterStoresData,
    region: state.region,
    filterState: state.filterState,
    city: state.city,
    storeCode: state.storeCode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCommonParmeter: (obj) => {
      dispatch({ type: "GET_COMMON_PARMETER", payload: obj });
    },
    getBellAppointmentSummary: (bell) => {
      dispatch({ type: "GET_BELL_APPOINTMENT_SUMMARY", payload: bell });
    },
    getBellCampaignSummary: (summary) => {
      dispatch({ type: "GET_REPORT_CAMPAIGN_SUMMARY", payload: summary });
    },
    getVideoCallSummary: (video) => {
      dispatch({ type: "GET_VIDEO_CALL_SUMMARY", payload: video });
    },
    getFilterStoredetails: (store) => {
      dispatch({ type: "FILTER_STORE_DATA", payload: store });
    },
    getFilterRegionDetails: (region) => {
      dispatch({ type: "REGION_DATA", payload: region });
    },
    getFilterStateDetails: (state) => {
      dispatch({ type: "STATE_DATA", payload: state });
    },
    getFilterCityDetails: (city) => {
      dispatch({ type: "CITY_DATA", payload: city });
    },
    getFilterStoreCodeDetails: (storeCode) => {
      dispatch({ type: "STORE_DATA", payload: storeCode });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoCallSummary);

import axios from "axios";
import config from "../config";

class APICaller {
  async PostWithParams(url, inputParams) {
    try {
      const response = await axios(config.apiUrl + url, {
        method: "POST",
        params: inputParams,
        // headers: authHeader(),
      });
      return await response.data;
    } catch (error) {
      if (
        error.response.status === 401 &&
        /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
      ) {
        window.location.href = "/SessionExpired";
      }
      console.log(error);
    }
  }

  async PostWithHeader(url, header) {
    try {
      const response = await axios(config.apiUrl + url, {
        method: "POST",
        headers: header,
      });

      return await response.data;
    } catch (error) {
      if (
        error.response.status === 401 &&
        /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
      ) {
        window.location.href = "/SessionExpired";
      }
    }
  }
}

export default APICaller;

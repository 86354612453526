import React, { Component } from 'react'
import { commonService } from "../../Utils/common.service";
import { connect } from "react-redux";
// import { Bar } from 'react-chartjs-2';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import DataNotFoundImg from '../../assets/images/widget-no-data.svg'
export class ProductPerformance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [],
      campaignListOrderBy: "DESC",
    }
  }

  componentDidMount() {
    this.getTopProductPerformance()
    this.props.onRef(this)
  }

  handleOrder = async (event) => {
    await this.setState({
      campaignListOrderBy: event.target.value,
    })
    this.props.getLoader(true)
    this.getTopProductPerformance()
  }

  getTopProductPerformance() {
    let request = this.props.commonParmeter;
    let programCode = window.sessionStorage.getItem('programCode')
    commonService.DashboardTopItemSoldList(request.storeCode, request.startDate, request.endDate, programCode, this.state.campaignListOrderBy, request.type, request.limit)
      .then(response => {
        if (response.isSuccess === "true" && response.data !== null) {
          this.props.getLoader(false)
          for (let i = 0; i < response.data.length; i++) {
            var resultData = response.data.map(e => ({ name: e.itemName, count: e.quantity }))
          }
          this.setState({
            chartData: resultData
          })
        } else {
          // alert('No Record Found  In Product Performance!!')
          this.setState({
            chartData: []
          })
          this.props.getLoader(false)
        }
      })
  }


  render() {
    return (
      <div>
        <div className="header" style={{ marginBottom: '20px' }}>
          <h3>Top Product Performance</h3>
          {/* <Select placeholder='Top Performing' options={regionOptions} /> */}
          { this.state.chartData.length === 0?undefined:<select className="select-campaign" onChange={(e) => this.handleOrder(e)}>
            <option value="DESC">Top Performing</option>
            <option value="ASC">Bottom Performing</option>
          </select>}
        </div>
        <div className="description">
          <div className="graph-con">
          { this.state.chartData.length === 0?<img className="imagesize" alt="" src={DataNotFoundImg}/>: <BarChart
              width={590}
              height={300}
              data={this.state.chartData.length ? this.state.chartData : []}
              margin={{
                top: 5, right: 90, left: -25, bottom: 5,
              }}
              barSize={35}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey='count' fill='#5d62b5' />
              {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
            </BarChart>}
            {
              !this.state.chartData.length &&
              // <p className="text-danger">No Record Found !!</p>
              <div className="alert alert-danger performance">
                <strong>No Record Found !!</strong>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    commonParmeter: state.commonParmeter
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCommonParmeter: (obj) => { dispatch({ type: 'GET_COMMON_PARMETER', payload: obj }) },
    getLoader: (bool) => { dispatch({ type: 'GET_LOADER', payload: bool }) },
  };
};



export default connect(mapStateToProps, mapDispatchToProps)(ProductPerformance);
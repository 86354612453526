export function encryption(plaintext, type = "enc") {
    var CryptoJS = require("crypto-js");
    var key = CryptoJS.enc.Utf8.parse('sblw-3hn8-sqoy19');
    var iv = CryptoJS.enc.Utf8.parse('sblw-3hn8-sqoy19');
    if (type === "enc") {
        var ciphertext = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plaintext), key,
            {
                keySize: 128 / 8,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
        var New_ciphertext = ciphertext.toString();
        return New_ciphertext;
    }
}
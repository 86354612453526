import React, { Component } from 'react';
import { Table } from 'semantic-ui-react'
// import filter from '../../../assets/images/icons/filter.svg';
// import Collapse from 'react-bootstrap/Collapse';
import download from '../../../assets/images/icons/download.png';
import report_download from '../../../assets/images/icons/report_download.svg';
import { connect } from 'react-redux';
import { commonService } from '../../../Utils/common.service'
//import "@technikhil/react-daterangepicker/styles.css";
//import ReactDaterangepicker from '@technikhil/react-daterangepicker';
import { Spinner } from 'react-bootstrap';
//import moment from 'moment';
import { Pagination as Pagination1 } from 'semantic-ui-react';
import { CSVLink } from "react-csv";
import ReportBulkDownload from '../../view/ReportBulkDownload';
// eslint-disable-next-line
import { event } from 'jquery';

import { endOfDay, format, startOfDay } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import DataNotFoundImg from '../../../assets/images/widget-no-data.svg';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import moment from 'moment';
const predefinedRanges = [
    {
        label: 'Last 7 days',
        value: [subDays(new Date(), 6), new Date()],
        placement: 'left'
    },
    {
        label: 'Last month',
        value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
        placement: 'left'
    },
    {
        label: 'Last 6 month',
        value: [startOfMonth(addMonths(new Date(), -6)), endOfMonth(addMonths(new Date(), -1))],
        placement: 'left'
    }
];

class OptionInputSummary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // dateRangeOpen: false,
            startDate: this.props.commonParmeter.startDate ? this.props.commonParmeter.startDate : '',
            endDate: this.props.commonParmeter.endDate ? this.props.commonParmeter.endDate : '',
            activePage: 1,
            totalpost: 0,
            postperPage: 10,
            optionInputSummary: [],
            tableHeader: [],
            loader: true,
            optionInputDetailsData: [],
            bellData: false,
            open: false,
            report: false
        }

        this.csvLink = React.createRef();
    }

    paginate(pageNumber) {
        this.setState({
            activePage: pageNumber
        })
    }

    componentDidMount() {
        let data = this.props.commonParmeter
        if (this.state.startDate == "") {
            data.startDate = moment().subtract(29, "days").format("MM/DD/YYYY")
            data.endDate = moment().format("MM/DD/YYYY")
        }
        data.storeCode = []
        this.props.getCommonParmeter(data)
        this.OptionInputSummary()
        this.setState({
            startDate: data.startDate,
            endDate: data.endDate,
        });

    }

    OptionInputSummary() {
        let data = this.props.commonParmeter
        let programCode = window.sessionStorage.getItem('programCode')
        let tableHeader = ''
        let optionInputSummary = this.state.optionInputSummary
        if (programCode !== null) {
            commonService.OptionInputSummary(data.startDate, data.endDate, programCode)
                .then(response => {
                    // console.log(response, "response data")
                    if (response.isSuccess === "true") {
                        optionInputSummary = response.data
                        for (let i = 0; i < optionInputSummary.length; i++) {
                            optionInputSummary[i].Date = this.convertDisplayDate(optionInputSummary[i].Date)
                        }
                        Object.keys(optionInputSummary).forEach((key) => (
                            delete optionInputSummary[key]["Option_"]
                        ));
                        tableHeader = response.data[0]
                        tableHeader = Object.keys(tableHeader)
                        // tableHeader[tableHeader.length-1]="Counts"
                        // for(let i= 0; i<tableHeader.length; i++){
                        //     if(tableHeader[i].charAt(0) === 'O' && tableHeader[i].charAt(1)==='p'){
                        //         tableHeader[i] = tableHeader[i].substring(7)
                        //     }
                        // }
                        this.setState({
                            loader: false
                        })
                        this.setState({
                            optionInputSummary: optionInputSummary,
                            tableHeader: tableHeader,
                            totalpost: response.data.length,
                        })

                        this.props.getOptionInputSummary(this.state.optionInputSummary)
                    } else {
                        this.setState({
                            loader: false,
                            optionInputSummary: [],
                            totalpost: 0,
                        })
                        this.props.getOptionInputSummary([])
                    }
                })
        } else {
            this.props.history.push('/')
        }
    }
    convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
    }

    convertDisplayDate(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day,].join("-");
    }

    changeDateHandler = (val) => {
        let data = this.props.commonParmeter
        // if (val.fromDate !== undefined && val.toDate !== undefined) {
        //     let startDate = val.fromDate._d
        //     let endDate = val.toDate._d
        //     let data = this.props.commonParmeter
        //     var maxStartDate = moment().diff(val.fromDate.format('MM/DD/YYYY'), 'days');
        //     var maxEndDate = moment().diff(val.toDate.format('MM/DD/YYYY'), 'days');
        //     if (maxStartDate >= 0) {
        //         data.startDate = this.convert(startDate)
        //     }
        //     else {
        //         alert("Date must be equal or before today Please enter a valid Start Date")
        //     }
        //     if (maxEndDate >= 0) {
        //         data.endDate = this.convert(endDate)
        //     }
        //     else {
        //         alert("Date must be equal or before today Please enter a valid End Date")
        //     }
        data.startDate = format(new Date(val[0]), 'MM/dd/yyyy')
        data.endDate = format(new Date(val[1]), 'MM/dd/yyyy')
        console.log('data', data)
        if (data.startDate !== '' && data.endDate !== '') {
            this.props.getCommonParmeter(data)
            this.OptionInputSummary()
        }
        // }
    }

    downloadfileBtn(currentDate, index) {
        this.setState({
            loader: true
        })
        // console.log(currentDate)
        let data = this.props.commonParmeter
        let programCode = window.sessionStorage.getItem('programCode')
        let optionDetailData = []
        let optionInputDetailsData = []
        commonService.GetBellOptionInpuOutputSummery(data.startDate, data.endDate, programCode)
            .then(response => {
                // console.log(response, "option Input")
                if (response.isSuccess === 'true') {
                    optionDetailData = response.data
                    for (let i = 0; i < response.data.length; i++) {
                        optionDetailData[i].date = this.convertDisplayDate(optionDetailData[i].date)
                        if (currentDate === optionDetailData[i].date) {
                            optionInputDetailsData.push(response.data[i])
                        }
                    }

                    this.setState({
                        loader: false,
                        optionInputDetailsData: optionInputDetailsData,
                        downloadIndex: index,
                        bellData: false
                    })
                    setTimeout(
                        this.csvLink.current.link.click(), 500
                    )
                }
                else {
                    this.setState({
                        loader: false,
                        // BellTicketDetails: 'No Record Found',
                        bellData: true
                    })
                }
            })
    }
    emptyCsvDataFile = () => {
        this.setState({
            optionInputDetailsData: []
        })
    }
    datePickHandle() {
        this.setState({
            open: false,
            report: false
        })
    }
    handleReportDownload() {
        this.setState({
            report: !this.state.report,
            open: false
        })
    }
    handelcloseBlockReport = () => {
        this.setState({
            report: !this.state.report,
            // open: false
        })
    }
    render() {
        const { afterToday } = DateRangePicker;
        const { report, activePage, totalpost, postperPage, loader, tableHeader, optionInputDetailsData } = this.state
        const pageNumbers = [];
        const indexOfLastPost = activePage * postperPage;
        const indexOfFirstPost = indexOfLastPost - postperPage;
        let currentPosts = null;
        // eslint-disable-next-line
        {
            currentPosts = this.props.reportOptionInputSummary != null ?
                this.props.reportOptionInputSummary.slice(indexOfFirstPost, indexOfLastPost) : this.props.reportOptionInputSummary
        }

        for (let index = 1; index <= Math.ceil(totalpost / postperPage); index++) {
            pageNumbers.push(index);
        }
        let startD = this.props.commonParmeter.startDate
        let endD = this.props.commonParmeter.endDate
        return (
            <>
                {loader &&
                    <div className="loader">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                }
                <div className="reports campaign">
                    <div className="page-title">
                        <div className="">
                            <div className="title">
                                <div className="">
                                    <h3>Whatsapp Option Input Report</h3>
                                    <p>Reports</p>
                                </div>
                                <div className=' date-range-picker'>
                                    <div className="d-flex date-range-picker" >
                                        <img
                                            src={report_download}
                                            // className="ml-3"
                                            onClick={() => this.handleReportDownload()}
                                            title="Download Reports"
                                            alt=""
                                        />
                                        <div onClick={() => this.datePickHandle()}>
                                            {/* <ReactDaterangepicker
                                                open="right"
                                                theme="light"
                                                format="DD/MM/YYYY"
                                                onRangeSelected={range => {
                                                    this.changeDateHandler(range)
                                                }}
                                            /> */}
                                            <DateRangePicker
                                                disabledDate={afterToday()}
                                                ranges={predefinedRanges}
                                                showOneCalendar
                                                placement="bottomEnd"
                                                // defaultValue={[startOfDay(subDays(new Date(), 29)), endOfDay(new Date())]}
                                                onOk={this.changeDateHandler}
                                                onChange={this.changeDateHandler}
                                                value={[new Date(this.props.commonParmeter.startDate), new Date(this.props.commonParmeter.endDate)]}

                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {report ?
                        <ReportBulkDownload startDate={this.props.commonParmeter.startDate} endDate={this.props.commonParmeter.endDate} onOk={this.changeDateHandler}
                            onChange={this.changeDateHandler} closeReport={this.handelcloseBlockReport} />
                        :
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    {tableHeader.map((ele, index) => {
                                        return (
                                            <Table.HeaderCell key={index}>{ele}</Table.HeaderCell>
                                        )
                                    })}
                                    <Table.HeaderCell>Action</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {currentPosts.length ?
                                    currentPosts.map((ele, index) => {
                                        return (
                                            <Table.Row key={index}>
                                                {
                                                    Object.values(ele).map((e, i) => {
                                                        return (
                                                            <Table.Cell key={i}>
                                                                <h4>{e}</h4>
                                                            </Table.Cell>
                                                        )
                                                    })
                                                }
                                                <Table.Cell>
                                                    <div>
                                                        <button className="table-down" key={index}
                                                            onClick={() => { this.downloadfileBtn(ele.Date, index) }}>
                                                            <img src={download} alt="" width="20px" />
                                                            <small>Download</small>

                                                        </button>

                                                        <CSVLink data={optionInputDetailsData.length ? optionInputDetailsData : 'No Record Found!!'}
                                                            filename={"OptionInputSummary_" + ele.Date + ".csv"}
                                                            className='hidden'
                                                            ref={this.csvLink}
                                                            target='_blank'
                                                        >
                                                        </CSVLink>


                                                    </div>



                                                    {/* {optionInputDetailsData.length && optionInputDetailsData !== null && this.state.downloadIndex === index && !this.state.bellData ?
                                                        <CSVLink data={optionInputDetailsData.length ? optionInputDetailsData : 'No Record Found!!'}
                                                            filename={"OptionInputSummary_" + ele.Date + ".csv"}>
                                                            <button className="table-down" onClick={this.emptyCsvDataFile} >
                                                                <img src={download} alt="" width="20px" />
                                                            </button>
                                                            <small>Download</small>
                                                        </CSVLink> :
                                                        <>
                                                            <button className="table-down" onClick={() => { this.downloadfileBtn(ele.Date, index) }} >
                                                                <img src={download} alt="" width="20px" />
                                                            </button>
                                                            <small>Proceed</small>
                                                        </>
                                                    }
                                                    {
                                                        this.state.bellData &&
                                                        <p className="text-danger mt-1">No Record Found</p>
                                                    } */}
                                                </Table.Cell>
                                            </Table.Row>

                                        )
                                    })
                                    : <Table.Row>
                                        <Table.Cell>
                                            <img className="imagesize" alt="" src={DataNotFoundImg} />
                                            <p className="text-danger">No Record Found!!</p>
                                        </Table.Cell>
                                    </Table.Row>}
                            </Table.Body>

                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='12'>
                                        {/* <Menu floated='right' pagination> */}
                                        <ul className='pagination-div'>
                                            {
                                                pageNumbers.length > 1 ?
                                                    <Pagination1
                                                        boundaryRange={0}
                                                        defaultActivePage={1}
                                                        ellipsisItem={null}
                                                        prevItem={pageNumbers.length > 1 && this.state.activePage !== 1 ? "Prev" : null}
                                                        nextItem={pageNumbers.length > 1 && this.state.activePage !== pageNumbers.length ? "Next" : null}
                                                        pointing
                                                        //secondary
                                                        firstItem={pageNumbers.length >= 10 && this.state.activePage !== 1 ? "First Page" : null}
                                                        lastItem={pageNumbers.length >= 10 && this.state.activePage !== pageNumbers.length ? "Last Page" : null}
                                                        totalPages={pageNumbers.length}
                                                        siblingRange={4}
                                                        onPageChange={(event, data) => this.paginate(data.activePage)}
                                                    />
                                                    :
                                                    null
                                            }
                                        </ul>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        commonParmeter: state.commonParmeter,
        reportOptionInputSummary: state.reportOptionInputSummary,
        filterStoresData: state.filterStoresData,
        region: state.region,
        filterState: state.filterState,
        city: state.city,
        storeCode: state.storeCode

    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCommonParmeter: (obj) => { dispatch({ type: 'GET_COMMON_PARMETER', payload: obj }) },
        getOptionInputSummary: (summary) => { dispatch({ type: 'GET_REPORT_OPTION_INPUT_SUMMARY', payload: summary }) },
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(OptionInputSummary);
export function decryption(ciphertext, type = "dec") {
    var CryptoJS = require("crypto-js");
    var key = CryptoJS.enc.Utf8.parse('sblw-3hn8-sqoy19');
    var iv = CryptoJS.enc.Utf8.parse('sblw-3hn8-sqoy19');
    if (type === "dec") {
        var Decrpttext = CryptoJS.AES.decrypt((CryptoJS.enc.Utf8.parse(ciphertext)).toString(CryptoJS.enc.Utf8), key,
            {
                keySize: 128 / 8,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
        return Decrpttext.toString(CryptoJS.enc.Utf8);
    }
}
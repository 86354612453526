import React from "react"
import logo from '../assets/images/logo.png';
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import LoginService from "../component/LoginService"
import { NotificationContainer, NotificationManager } from "react-notifications";
import { encryption } from "./encryption";
import { decryption } from "./decryption";


class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailID: "",
      password: "",
      loading: false,
      submitted: false,
      programCode: "",
      isMobileView: false,
      emailIdValidation: "",
      passwordValidation: "",
    };

    this.LoginService = new LoginService();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator();
  }

  handleChange = (e) => {
    this.setState({
      programCode: e.target.value,
    });
  }

  handleEmailChange = (e) => {
    this.setState({
      emailID: e.target.value,
    });
  }

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value,
    });
    if (!e.target.value) {
      this.setState({ passwordValidation: "Enter Password." });
    }
  };

  componentDidMount() {
    let url = new URL(window.location.href);
    let ProgramCode = url.searchParams.get("Programcode");
    let UserID = url.searchParams.get("UserID");
    if (ProgramCode !== null && UserID !== null) {
      while (UserID.indexOf(' ') >= 0) {
        UserID = UserID.replace(" ", "+")
      }
      while (ProgramCode.indexOf(' ') >= 0) {
        ProgramCode = ProgramCode.replace(" ", "+")
      }
      UserID = decryption(UserID);
      ProgramCode = decryption(ProgramCode);
      window.sessionStorage.setItem("programCode", ProgramCode)
      window.sessionStorage.setItem("UserId", UserID)
      let commonParameter = this.props.commonParmeter
      commonParameter.programCode = ProgramCode;
      this.props.getCommonParmeter(commonParameter)
      let inputData = {
        UserID: UserID,
        ProgCode: ProgramCode
      }
      this.LoginService.PostWithParams("/Reports/validateUser", inputData)
        .then((response) => {
          let resValid = response.message;
          if (resValid === "Valid Login") {
            window.localStorage.setItem('isNumberMasking',response.roleBasedFeature.mobileMasking)
            window.localStorage.setItem('mobileMaskingPrefix',response.roleBasedFeature.mobileMaskingPrefix)
            window.localStorage.setItem('mobileMaskingSufix',response.roleBasedFeature.mobileMaskingSufix)
            this.props.history.push('/dashboard')
          }
          else {
            this.props.history.push('/');
          }
        });

      //this.props.history.push('/dashboard')
    }
    if (ProgramCode === null || UserID === null) {
      this.props.history.push('/');
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const { programCode, emailID, password } = this.state;
    this.setState({
      error: '',
      emailID,
      programCode,
      password
    });
   
      if (this.state.programCode && this.state.emailID && this.state.password) {
        const { emailID, password } = this.state;
        var X_Authorized_userId = encryption(emailID, "enc");
        let X_Authorized_password = encryption(password, "enc");
        let X_Authorized_Programcode = encryption(programCode, "enc");
  
        let X_Authorized_Devicesource = encryption(
          this.state.isMobileView ? "M" : "W",
          "enc"
        );
        let X_Authorized_Domainname = encryption('https://qa-ui.shopster.live', "enc");
        if (X_Authorized_Programcode !== null && X_Authorized_userId !== null && X_Authorized_password !== null) {
          this.LoginService.PostWithHeader("/Reports/AuthenticateUser", {
            "X-Authorized-Programcode": X_Authorized_Programcode,
            "X-Authorized-userId": X_Authorized_userId,
            "X-Authorized-password": X_Authorized_password,
            "X-Authorized-Domainname": X_Authorized_Domainname,
            "X-Authorized-Devicesource": X_Authorized_Devicesource,
          })
            .then((response) => {
              let resValid = response.data.status;
              this.setState({
                loading: true,
              });
              if (resValid === true) {
                window.sessionStorage.setItem("ERS", true);
                window.sessionStorage.setItem('programCode', programCode)
                window.localStorage.setItem('isNumberMasking',response.data.roleBasedFeature.mobileMasking)
                window.localStorage.setItem('mobileMaskingPrefix',response.data.roleBasedFeature.mobileMaskingPrefix)
                window.localStorage.setItem('mobileMaskingSufix',response.data.roleBasedFeature.mobileMaskingSufix)
               
                let commonParameter = this.props.commonParmeter
                commonParameter.programCode = programCode;
                this.props.getCommonParmeter(commonParameter)
                this.props.history.push('/dashboard')
                // if(this.state.programCode === "motherhood"){
                //   this.props.history.push('/MotherhoodDashboard')
                // }
                // else{
                //   this.props.history.push('/dashboard')
                // }
              }
              else if (response.data.message === null) {
                NotificationManager.error("Invalid ProgramCode")
              }
              else {
                NotificationManager.error(
                  response.data.message
                );
                this.props.history.push('/')
              }
            })
            .catch((error) => {
              console.log(error);
              window.localStorage.setItem(
                "isNumberMasking",
                false
              );
              window.localStorage.setItem(
                "maskingPrefix",
               0
              );
            });
        }
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
  }

  render() {
    const { programCode, password, emailID, submitted, error } = this.state;
    return (
      <div className="page_content">
        <NotificationContainer />
        <div className="login_form_wrap">
          <form name="form" onSubmit={this.handleSubmit.bind(this)}>
            <div className="logo_wrap">
              <img src={logo} alt="logo" />
            </div>
            <div className="tag-manager">SHOPSTER DASHBOARD</div>
            <div className="form_wrap">
              <div className="form_title">
                <h4>Login</h4>
              </div>
              <div className={'form-group' + (submitted && !programCode && !error ? ' has-error' : '')}>
                {/* <label htmlFor="username">Username</label> */}
                <input type="text" placeholder="PROGRAMCODE*" className="form-control " name="programcode" value={this.state.programCode} onChange={(e) => this.handleChange(e)} />
                {submitted && !programCode && !error &&
                  <div className="help-block text-danger">ProgramCode is required</div>
                }
              </div>
              <div className={'form-group' + (submitted && !emailID && !error ? ' has-error' : '')}>
                {/* <label htmlFor="username">Username</label> */}
                <input type="text" placeholder="EMAILID*" className="form-control" name="username" value={this.state.emailID} onChange={(e) => this.handleEmailChange(e)} />
                {submitted && !emailID && !error &&
                  <div className="help-block text-danger">EmailID is required</div>
                }
              </div>
              <div className={'form-group' + (submitted && !password && !error ? ' has-error' : '')}>
                {/* <label htmlFor="password">Password</label> */}
                <input type="password" placeholder="PASSWORD*" className="form-control" name="password" value={this.state.password} onChange={(e) => this.handlePasswordChange(e)} />
                {submitted && !password && !error &&
                  <div className="help-block text-danger">Password is required</div>
                }
              </div>
              <div className="action_btn">
                <button
                  onClick={this.handleSubmit.bind(this)}
                // disabled={loading}
                >SUBMIT
                </button>
              </div>
              {/* {error &&
                <div className={'alert alert-danger'}>UnAuthorized User</div>
              } */}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    commonParmeter: state.commonParmeter,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCommonParmeter: (obj) => { dispatch({ type: 'GET_COMMON_PARMETER', payload: obj }) },
  };
};



export default connect(mapStateToProps, mapDispatchToProps)(Login);
import React, { Component } from "react";
import { endOfDay, format, startOfDay } from "date-fns";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import { connect } from "react-redux";
import { commonService } from "../../Utils/common.service";
import "../../App.scss";
import { CSVLink } from "react-csv";
import { handleMaskingNumber } from "../mobileMasking";
import subDays from 'date-fns/subDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import moment from "moment";
const predefinedRanges = [
  {
    label: 'Last 7 days',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left'
  },
  {
    label: 'Last month',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left'
  },
  {
    label: 'Last 6 month',
    value: [startOfMonth(addMonths(new Date(), -6)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left'
  }
];

class ReportBulkDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: this.props.commonParmeter.startDate ? this.props.commonParmeter.startDate : '',
      endDate: this.props.commonParmeter.endDate ? this.props.commonParmeter.endDate : '',
      checkboxArray: [],
      AppointmentSummarydata: [],
      bellChatDetailsdata: [],
      bellTicketDetails: [],
      BellCampaignDetailSummarydata: [],
      videoCallDetailsdata: [],
      phygitalSummaryData: [],
      StaffLeaderBoardSummarydata: [],
      optionInputSummary: [],
      ctaActionSummary: [],
      ticketSummaryMH: [],
    };
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }
  changeDateHandler = (val) => {
    //  debugger
    let data = this.props.commonParmeter
    data.startDate = format(new Date(val[0]), 'MM/dd/yyyy')
    data.endDate = format(new Date(val[1]), 'MM/dd/yyyy')
    console.log('data', data)
    if (data.startDate !== '' && data.endDate !== '') {
      this.props.getCommonParmeter(data)
      this.setState({
        startDate: data.startDate,
        endDate: data.endDate,
      });
      // window.location.reload(false);
    }

    // let startDate = format(new Date(val[0]), "MM/dd/yyyy");
    // let endDate = format(new Date(val[1]), "MM/dd/yyyy");

    // if (startDate !== "" && endDate !== "") {
    //   this.setState({
    //     startDate: startDate,
    //     endDate: endDate,
    //   });
    // }


  };

  componentDidMount() {

    let data = this.props.commonParmeter
    data.startDate = this.state.startDate;
    data.endDate = this.state.endDate;
    console.log("data", data.startDate, data.endDate)
    // this.props.getCommonParmeter(data)
    data.storeCode = []
    this.props.getCommonParmeter(data)
  }


  // setSelectedData = (value) => {
  //    debugger
  //   console.log("val date dashboard ", value);
  //   let data = this.props.commonParmeter;
  //   data.startDate = format(new Date(value[0]), "MM/dd/yyyy");
  //   data.endDate = format(new Date(value[1]), "MM/dd/yyyy");
  //   this.setState({

  //   })
  //   console.log("data setSelectedData", data);
  // }
  handleApply = async () => {
    // debugger
    if (this.state.videoCallDetailsdata.length !== 0) {
      await document.getElementById("videocall_summary").click();
    }
    if (this.state.BellCampaignDetailSummarydata.length !== 0) {
      await document.getElementById("campaign_summary").click();
    }
    if (this.state.phygitalSummaryData.length !== 0) {
      await document.getElementById("phygital_summary").click();
    }
    if (this.state.bellChatDetailsdata.length !== 0) {
      await document.getElementById("bell_chat_details").click();
    }
    if (this.state.StaffLeaderBoardSummarydata.length !== 0) {
      await document.getElementById("staff_leader_board").click();
    }
    if (this.state.AppointmentSummarydata.length !== 0) {
      await document.getElementById("appointment_summary").click();
    }
    if (this.state.bellTicketDetails.length !== 0) {
      await document.getElementById("ticket_summary").click();
    }
    if (this.state.optionInputSummary.length !== 0) {
      await document.getElementById("option_input_summary").click();
    }
    if (this.state.ticketSummaryMH.length !== 0) {
      await document.getElementById("ticket_summary_mh").click();
    }
    if (this.state.ctaActionSummary.length !== 0) {
      await document.getElementById("cta_action_summary").click();
    }
    setTimeout(function () {
      window.location.reload();
    }, 10000);
  };
  emptyCsvDataFile = () => {
    this.setState({
      BellCampaignDetailSummarydata: [],
    });
  };

  handelclose() {

  }
  handleCheckboxValue(event) {
    // debugger
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let storeCode = this.state.storeCode;
    let checkboxArray = this.state.checkboxArray;
    let isNumberMasking = false;
    let maskingPrefix = 0;
    let maskingSufix = 0;
    let ticketSourceName = "chatbot";
    let programCode = window.sessionStorage.getItem("programCode");

    isNumberMasking =
      window.localStorage.getItem("isNumberMasking") === "true" ? true : false;
    maskingPrefix = window.localStorage.getItem("mobileMaskingPrefix");
    maskingSufix = window.localStorage.getItem("mobileMaskingSufix");

    if (this.state.startDate.length !== 0 && this.state.endDate.length !== 0) {
      if (event.target.value === "videocall_summary") {
        if (event.target.checked === true) {
          checkboxArray.push(event.target.value);
          commonService
            .VideoCallSummary(storeCode, startDate, endDate, programCode)
            .then((response) => {
              if (response.isSuccess === "true") {
                if (isNumberMasking === true) {
                  for (let i = 0; i < response.data.length; i++) {
                    var mskMobile = (response.data[i].customerMobileNo =
                      handleMaskingNumber(
                        response.data[i].customerMobileNo,
                        maskingPrefix,
                        maskingSufix
                      ));
                    response.data[i].customerMobileNo = mskMobile;
                  }
                }
                this.setState({
                  loader: false,
                  videoCallDetailsdata: response.data,
                });
              } else {

                this.setState({
                  loader: false,
                  videoCallDetailsdata: "No Record Found",
                });
              }
            });
        } else {
          const index = checkboxArray.indexOf(event.target.value);
          if (index > -1) {
            checkboxArray.splice(index, 1);
          }
          this.setState({
            videoCallDetailsdata: [],
          });
        }
      }
      if (event.target.value === "campaign_summary") {
        if (event.target.checked === true) {
          checkboxArray.push(event.target.value);
          // this.setState({
          //     checkboxArray:checkboxArray
          // })
          commonService
            .BellCampaignDetailSummery("", startDate, endDate, programCode)
            .then((response) => {
              if (response.isSuccess === "true") {
                this.setState({
                  loader: false,
                  BellCampaignDetailSummarydata: response.data,
                });
              } else {
                this.setState({
                  loader: false,
                  BellCampaignDetailSummarydata: "No Record Found",
                });
              }
            });
        } else {
          const index = checkboxArray.indexOf(event.target.value);
          if (index > -1) {
            checkboxArray.splice(index, 1);
          }
          this.setState({
            BellCampaignDetailSummarydata: [],
          });
        }
      }
      if (event.target.value === "phygital_summary") {
        if (event.target.checked === true) {
          checkboxArray.push(event.target.value);

          commonService
            .PhygitalDetails("", startDate, endDate, programCode)
            .then((response) => {
              if (response.isSuccess === "true") {
                this.setState({
                  loader: false,
                  phygitalSummaryData: response.data,
                });
              } else {
                this.setState({
                  loader: false,
                  phygitalSummaryData: "No Record Found",
                });
              }
            });
        } else {
          const index = checkboxArray.indexOf(event.target.value);
          if (index > -1) {
            checkboxArray.splice(index, 1);
          }
          this.setState({
            phygitalSummaryData: [],
          });
        }
      }
      if (event.target.value === "bell_chat_details") {
        // debugger
        if (event.target.checked === true) {
          checkboxArray.push(event.target.value);

          commonService
            .BellChatDetails("", startDate, endDate, programCode)
            .then((response) => {
              if (response.isSuccess === "true") {

                this.setState({
                  loader: false,
                  bellChatDetailsdata: response.data,
                });
              } else {

                this.setState({
                  loader: false,
                  bellChatDetailsdata: "No Record Found",
                });
              }
            });
        } else {
          const index = checkboxArray.indexOf(event.target.value);
          if (index > -1) {
            checkboxArray.splice(index, 1);
          }
          this.setState({
            bellChatDetailsdata: [],
          });
        }
      }
      if (event.target.value === "staff_leader_board") {
        if (event.target.checked === true) {
          checkboxArray.push(event.target.value);

          commonService
            .StaffPerformanceDetails("", startDate, endDate, programCode)
            .then((response) => {
              // console.log(response.data, 'staff leader')
              if (response.isSuccess === "true") {
                this.setState({
                  loader: false,
                  StaffLeaderBoardSummarydata: response.data,
                });
              } else {
                this.setState({
                  loader: false,
                  StaffLeaderBoardSummarydata: "No Record Found",
                });
              }
            });
        } else {
          const index = checkboxArray.indexOf(event.target.value);
          if (index > -1) {
            checkboxArray.splice(index, 1);
          }
          this.setState({
            StaffLeaderBoardSummarydata: [],
          });
        }
      }
      if (event.target.value === "appointment_summary") {
        if (event.target.checked === true) {
          checkboxArray.push(event.target.value);

          commonService
            .BellAppointmentDetails("", startDate, endDate, programCode)
            .then((response) => {
              // console.log(response.data, 'appointmentdata')
              if (response.isSuccess === "true") {
                this.setState({
                  loader: false,
                  AppointmentSummarydata: response.data,
                });
              } else {
                this.setState({
                  loader: false,
                  AppointmentSummarydata: "No Record Found",
                });
              }
            });
        } else {
          const index = checkboxArray.indexOf(event.target.value);
          if (index > -1) {
            checkboxArray.splice(index, 1);
          }
          this.setState({
            AppointmentSummarydata: [],
          });
        }
      }
      if (event.target.value === "ticket_summary") {
        if (event.target.checked === true) {
          checkboxArray.push(event.target.value);

          commonService
            .BellTicketDetails("", startDate, endDate, programCode)
            .then((response) => {
              if (response.isSuccess === "true") {
                this.setState({
                  loader: false,
                  bellTicketDetails: response.data,
                });
              } else {
                this.setState({
                  loader: false,
                  BellTicketDetails: "No Record Found",
                });
              }
            });
        } else {
          const index = checkboxArray.indexOf(event.target.value);
          if (index > -1) {
            checkboxArray.splice(index, 1);
          }
          this.setState({
            bellTicketDetails: [],
          });
        }
      }
      if (event.target.value === "option_input_summary") {
        if (event.target.checked === true) {
          checkboxArray.push(event.target.value);

          commonService
            .GetBellOptionInpuOutputSummery(startDate, endDate, programCode)
            .then((response) => {
              if (response.isSuccess === "true") {
                this.setState({
                  loader: false,
                  optionInputSummary: response.data,
                });
              } else {
                this.setState({
                  loader: false,
                  optionInputSummary: "No Record Found",
                });
              }
            });
        } else {
          const index = checkboxArray.indexOf(event.target.value);
          if (index > -1) {
            checkboxArray.splice(index, 1);
          }
          this.setState({
            optionInputSummary: [],
          });
        }
      } if (event.target.value === "ticket_summary_mh") {
        if (event.target.checked === true) {
          checkboxArray.push(event.target.value);
          var sDate = startDate = format(new Date(this.state.startDate), 'yyyy-MM-dd')
          var eDate = endDate = format(new Date(this.state.endDate), 'yyyy-MM-dd')
          commonService
            .TicketSummaryMH(sDate, eDate, programCode, ticketSourceName)
            .then((response) => {
              if (response.isSuccess === "true") {
                this.setState({
                  loader: false,
                  ticketSummaryMH: response.data,
                });
              } else {
                this.setState({
                  loader: false,
                  TicketSummaryMH: "No Record Found",
                });
              }
            });
        } else {
          const index = checkboxArray.indexOf(event.target.value);
          if (index > -1) {
            checkboxArray.splice(index, 1);
          }
          this.setState({
            ticketSummaryMH: [],
          });
        }
      } else if (event.target.value === "cta_action_summary") {
        if (event.target.checked === true) {
          checkboxArray.push(event.target.value);

          commonService
            .CTAActionDetails(startDate, endDate, programCode)
            .then((response) => {
              console.log("CTAActionDetails", response.data);
              if (response.isSuccess === "true") {
                this.setState({
                  loader: false,
                  ctaActionSummary: response.data,
                });
              } else {
                this.setState({
                  loader: false,
                  ctaActionSummary: "No Record Found",
                });
              }
            });
        } else {
          const index = checkboxArray.indexOf(event.target.value);
          if (index > -1) {
            checkboxArray.splice(index, 1);
          }
          this.setState({
            ctaActionSummary: [],
          });
        }
      }
      this.setState({
        checkboxArray: checkboxArray,
      });
    }
    // else{
    //     console.log('no date')
    // }
  }
  

  render() {
    const { afterToday } = DateRangePicker;
    const {
      AppointmentSummarydata,
      ctaActionSummary,
      bellChatDetailsdata,
      bellTicketDetails,
      StaffLeaderBoardSummarydata,
      startDate,
      endDate,
      checkboxArray,
      optionInputSummary,
      ticketSummaryMH,
    } = this.state;
    return (
      <div className="bulk-main-div">
        <div className="crossicon_wrap" onClick={this.props.closeReport}>
          &times;
        </div>
        <div className="bulk-download">
          <div className="range_picker">
            <p>Pick a date range: </p>
            {/* <ReactDaterangepicker
                            open="left"
                            theme="light"
                            format="DD/MM/YYYY"
                            onRangeSelected={range => {
                                this.changeDateHandler(range)
                            }}
                        /> */}
            <DateRangePicker
              disabledDate={afterToday()}
              ranges={predefinedRanges}
              // value={[new Date(startD), new Date(endD)]}
              showOneCalendar
              placement="bottomEnd"
              onOk={this.props.onOk}
              onChange={this.props.onChange}
              // defaultValue={[startOfDay(subDays(new Date(), 29)), endOfDay(new Date())]}
              value={[new Date(this.props.commonParmeter.startDate), new Date(this.props.commonParmeter.endDate)]}
            // defaultValue={[startOfDay(subDays(new Date(), 29)), endOfDay(new Date())]}
            />
            {startDate.length > endDate.length ? (
              <p className="text-danger mt-0 " style={{ fontSize: "12px" }}>
                Please select a valid date range
              </p>
            ) : null}
          </div>
          <div className="cohort-shows">
            {/* <button className="closeClick" onClick={this.closeCohort}><img src={close_icon} /></button> */}
            {/* <div className="custom-control custom-checkbox">
              <input
                id="checkbox1"
                type="checkbox"
                disabled={
                  startDate.length !== 0 && endDate.length !== 0 ? false : true
                }
                className="custom-control-input"
                value="videocall_summary"
                onClick={(e) => this.handleCheckboxValue(e)}
              />
              <label className="custom-control-label" htmlFor="checkbox1">
                VideoCall Summary
              </label>
            </div> */}

            <div className="custom-control custom-checkbox">
              <input
                id="checkbox2"
                type="checkbox"
                disabled={
                  startDate.length !== 0 && endDate.length !== 0 ? false : true
                }
                className="custom-control-input"
                value="campaign_summary"
                onClick={(e) => this.handleCheckboxValue(e)}
              />
              <label className="custom-control-label" htmlFor="checkbox2">
                Campaign Summary
              </label>
            </div>
            <div className="custom-control custom-checkbox">
              <input
                id="checkbox3"
                type="checkbox"
                disabled={
                  startDate.length !== 0 && endDate.length !== 0 ? false : true
                }
                className="custom-control-input"
                value="phygital_summary"
                onClick={(e) => this.handleCheckboxValue(e)}
              />
              <label className="custom-control-label" htmlFor="checkbox3">
                Phygital Summary
              </label>
            </div>
            <div className="custom-control custom-checkbox">
              <input
                id="checkbox4"
                type="checkbox"
                disabled={
                  startDate.length !== 0 && endDate.length !== 0 ? false : true
                }
                className="custom-control-input"
                value="bell_chat_details"
                onClick={(e) => this.handleCheckboxValue(e)}
              />
              <label className="custom-control-label" htmlFor="checkbox4">
                Bell Chat Details
              </label>
            </div>
            <div className="custom-control custom-checkbox">
              <input
                id="checkbox5"
                type="checkbox"
                disabled={
                  startDate.length !== 0 && endDate.length !== 0 ? false : true
                }
                className="custom-control-input"
                value="staff_leader_board"
                onClick={(e) => this.handleCheckboxValue(e)}
              />
              <label className="custom-control-label" htmlFor="checkbox5">
                Staff Leader Board
              </label>
            </div>
            <div className="custom-control custom-checkbox">
              <input
                id="checkbox6"
                type="checkbox"
                disabled={
                  startDate.length !== 0 && endDate.length !== 0 ? false : true
                }
                className="custom-control-input"
                value="appointment_summary"
                onClick={(e) => this.handleCheckboxValue(e)}
              />
              <label className="custom-control-label" htmlFor="checkbox6">
                Appointment Summary
              </label>
            </div>
            <div className="custom-control custom-checkbox">
              <input
                id="checkbox7"
                type="checkbox"
                disabled={
                  startDate.length !== 0 && endDate.length !== 0 ? false : true
                }
                className="custom-control-input"
                value="ticket_summary"
                onClick={(e) => this.handleCheckboxValue(e)}
              />
              <label className="custom-control-label" htmlFor="checkbox7">
                Ticket Summary
              </label>
            </div>
            <div className="custom-control custom-checkbox">
              <input
                id="checkbox8"
                type="checkbox"
                disabled={
                  startDate.length !== 0 && endDate.length !== 0 ? false : true
                }
                className="custom-control-input"
                value="option_input_summary"
                onClick={(e) => this.handleCheckboxValue(e)}
              />
              <label className="custom-control-label" htmlFor="checkbox8">
                Option Input Summary
              </label>
            </div>

            {/* <div className="custom-control custom-checkbox">
              <input
                id="checkbox9"
                type="checkbox"
                disabled={
                  startDate.length !== 0 && endDate.length !== 0 ? false : true
                }
                className="custom-control-input"
                value="ticket_summary_mh"
                onClick={(e) => this.handleCheckboxValue(e)}
              />
              <label className="custom-control-label" htmlFor="checkbox9">
                Ticket Summary-Motherhood
              </label>
            </div> */}

            <div className="custom-control custom-checkbox">
              <input
                id="checkbox10"
                type="checkbox"
                disabled={
                  startDate.length !== 0 && endDate.length !== 0 ? false : true
                }
                className="custom-control-input"
                value="cta_action_summary"
                onClick={(e) => this.handleCheckboxValue(e)}
              />
              <label className="custom-control-label" htmlFor="checkbox10">
                CTA Action Summary
              </label>
            </div>

            <CSVLink
              id="videocall_summary"
              data={this.state.videoCallDetailsdata}
              filename={"VideoCallSummaryData.csv"}
            ></CSVLink>
            <CSVLink
              id="campaign_summary"
              data={this.state.BellCampaignDetailSummarydata}
              filename={"CampaignSummaryData.csv"}
            ></CSVLink>
            <CSVLink
              id="phygital_summary"
              data={this.state.phygitalSummaryData}
              filename={"phygitalSummaryData.csv"}
            ></CSVLink>
            <CSVLink
              id="bell_chat_details"
              data={bellChatDetailsdata}
              filename={"BellChatDetailsData.csv"}
            ></CSVLink>
            <CSVLink
              id="staff_leader_board"
              data={StaffLeaderBoardSummarydata}
              filename={"StaffLeaderBoardData.csv"}
            ></CSVLink>
            <CSVLink
              id="appointment_summary"
              data={AppointmentSummarydata}
              filename={"AppointmentSummaryData.csv"}
            ></CSVLink>
            <CSVLink
              id="ticket_summary"
              data={bellTicketDetails}
              filename={"TicketDetails.csv"}
            ></CSVLink>
            <CSVLink
              id="option_input_summary"
              data={optionInputSummary}
              filename={"OptionInputSummaryData.csv"}
            ></CSVLink>
            <CSVLink
              id="ticket_summary_mh"
              data={ticketSummaryMH}
              filename={"TicketSummaryDetails.csv"}
            ></CSVLink>
            <CSVLink
              id="cta_action_summary"
              data={ctaActionSummary}
              filename={"ctaActionSummary.csv"}
            ></CSVLink>
            {/* } */}
            {startDate.length !== 0 &&
              endDate.length !== 0 &&
              checkboxArray.length !== 0 ? (
              <button className="btn apply" onClick={() => this.handleApply()}>
                Download
              </button>
            ) : (
              <button disabled className="btn apply">
                Download
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commonParmeter: state.commonParmeter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCommonParmeter: (obj) => {
      dispatch({ type: "GET_COMMON_PARMETER", payload: obj });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportBulkDownload);

import React from "react";
import { Dropdown } from "semantic-ui-react";
import logo from "../../assets/images/icons/logo.svg";
import home from "../../assets/images/icons/home.svg";
import reportblueicon from "../../assets/images/icons/reportblueicon.svg";
import homeblckicon from "../../assets/images/icons/homeblckicon.svg";
import reports from "../../assets/images/icons/reports.svg";
import Dashboard from "../view/Dashboard";
import VideoCallSummary from "../view/Reports/VideoCallSummary";
import CampaignSummary from "../view/Reports/CampaignSummary";
import PhygitalSummary from "../view/Reports/PhygitalSummary";
import AppointmentSummary from "../view/Reports/AppointmentSummary";
import StaffLeaderBoard from "../view/Reports/StaffLeaderBoard";
import BellChatDetails from "../view/Reports/BellChatDetails";
import OptionInputSummary from "../view/Reports/OptionInputSummary";
import BellTicketSummary from "../view/Reports/BellTicketSummary";
import CtaAction from "../view/Reports/CtaAction";
import ReportBulkDownload from "../view/ReportBulkDownload";
import "../../style/Home.scss";
import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { commonService } from "../../Utils/common.service";
import MHTicketSummary from "../view/Reports/MHTicketSummary";
import MotherhoodDashboard from "../view/MotherhoodDashboard";

const drawerWidth = 270;
const drawerTopMargin = 69;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: "100px",
  },
  appBar: {
    width: "100%",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("md")]: {
      check: 1,
    },
    check: 0,
  },
  drawer: {
    width: drawerWidth,
    marginTop: drawerTopMargin,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    paddingLeft: 85,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("md")]: {
      paddingLeft: 300,
    },
    paddingLeft: 50,
  },
  drawerOpensetting: {
    width: drawerWidth,
    marginTop: drawerTopMargin,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    // [theme.breakpoints.up("xl")]: {
    //   width: theme.spacing(10) + 1,
    // },
  },
  drawerClosesetting: {
    marginTop: drawerTopMargin,
    borderStyle: "none",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: "60px",
    // [theme.breakpoints.up("xl")]: {
    //   width: theme.spacing(9) + 1,
    // },
  },
}));

function Home(props) {
  const classes = useStyles();
   // eslint-disable-next-line
  const theme = useTheme();
  const [Draweropen, DrawersetOpen] = React.useState(false);
  const [showReports, setshowReports] = React.useState(false);
  const [livedata, setlivedata] = React.useState([]);
  const [programCode, setProgramCode] = React.useState('');
  const handleDrawerMouseClose = () => {
    DrawersetOpen(false);
    setshowReports(false);
  };
  const handleDrawerMouseopen = () => {
    DrawersetOpen(true);
  };
  const handleDrawerOpen = () => {
    DrawersetOpen(!Draweropen);
    setshowReports(false);
  };

  const handleReport = () => {
    console.log("hi");
    setshowReports(!showReports);
  };
  const handleReportDashboard = () => {
    console.log("by");
    setshowReports(false);
  };
  const logoutHandler = () => {
    props.history.push("/");
    sessionStorage.clear();
  };

  useEffect(() => {
    let programCode = window.sessionStorage.getItem("programCode");
    setProgramCode(programCode);
    commonService.DashboardBoxLive(programCode).then((response) => {
      if (response.isSuccess === "true" && response.data !== null) {
        setlivedata(response.data[0]);
      }
    });
  }, []);

  return (
    <div className="dashbordcontainer">
      <div className="{classes.root}">
        <CssBaseline />
        <AppBar position="fixed" color="white" className={clsx(classes.appBar)}>
          <Toolbar>
            <div className="header">
              <div className="top-header">
                <div className="header-logo">
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton)}
                  >
                    <MenuIcon />{" "}
                  </IconButton>
                  <Link to="/dashboard">
                    <img src={logo} alt="" />
                  </Link>
                </div>
                <div className="d-flex">
                  {/* <div className="live-toolbar col-sm-4">
                    <span className="btn btn_live">
                      Live
                      <span className="live-icon"></span>
                    </span>
                  </div>

                  <div className="col-sm-12  d-flex align-items-center">
                    <div className="card mr-2">
                      <div className="card-header py-1">Landed On WebBot</div>
                      <div className="card-body">
                        {livedata?.landedOnWebBot}
                      </div>
                    </div>
                    <div className="card mr-2">
                      <div className="card-header py-1">Shopping Bag</div>
                      <div className="card-body">{livedata?.shoppingBag}</div>
                    </div>
                    <div className="card">
                      <div className="card-header py-1">Orders</div>
                      <div className="card-body">{livedata?.orders}</div>
                    </div>
                  </div> */}
                </div>
                <div className="header-right">
                  <Dropdown className="user-img">
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          logoutHandler();
                        }}
                        text="Logout"
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </div>
      <div className="drawer">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpensetting]: Draweropen,
            [classes.drawerClosesetting]: !Draweropen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpensetting]: Draweropen,
              [classes.drawerClosesetting]: !Draweropen,
            }),
          }}
        >
          <List
            onMouseEnter={handleDrawerMouseopen}
            onMouseLeave={handleDrawerMouseClose}
          >
            <ListItem
              button
              key={"Dashboard"}
              onClick={handleReportDashboard}
              className={
                window.location.pathname === "/dashboard" ||
                window.location.pathname === "/"
                  ? "active menu-background"
                  : ""
              }
            >
              <h3
                style={{ height: "20px" }}
                className=" d-flex flex-direction-row align-items-center"
              >
                {window.location.pathname === "/dashboard" ||
                window.location.pathname === "/" ? (
                  <img src={home} alt="Dashboard" />
                ) : (
                  <img src={homeblckicon} alt="Dashboard" />
                )}
                <Link
                  to={programCode === "motherhood" ? '/dashboard':"/dashboard"}
                  // ||  window.location.pathname === "/MotherhoodDashboard"
                  className={
                    window.location.pathname === "/dashboard"  ||
                    window.location.pathname === "/"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  onClick={handleReportDashboard}
                >
                  <ListItemText
                    className={
                      window.location.pathname === "/dashboard" ||
                      window.location.pathname === "/"
                        ? "active active-text list-item-text"
                        : " list-item-text"
                    }
                    primary={"Dashboard"}
                  />
                </Link>
              </h3>
            </ListItem>

            <ListItem
              button
              key={"Reports"}
              onClick={handleReport}
              className={
                window.location.pathname === "/reports/videocall-summary" ||
                window.location.pathname === "/reports/campaign-summary" ||
                window.location.pathname === "/reports/phygital-summary" ||
                window.location.pathname === "/reports/bell-chatDetails" ||
                window.location.pathname === "/reports/staff-leader-board" ||
                window.location.pathname === "/reports/appointment-summary" ||
                window.location.pathname ===
                  "/reports/whatsapp-option-input-report" ||
                window.location.pathname === "/reports/ticket-summary" ||
                window.location.pathname === "/reports/cta-action"
                  ? "active menu-background list-item-text"
                  : "list-item-text"
              }
            >
              <h3
                style={{ height: "20px" }}
                className=" d-flex flex-direction-row align-items-center"
              >
                {window.location.pathname === "/reports/videocall-summary" ||
                window.location.pathname === "/reports/campaign-summary" ||
                window.location.pathname === "/reports/phygital-summary" ||
                window.location.pathname === "/reports/bell-chatDetails" ||
                window.location.pathname === "/reports/staff-leader-board" ||
                window.location.pathname === "/reports/appointment-summary" ||
                window.location.pathname ===
                  "/reports/whatsapp-option-input-report" ||
                window.location.pathname === "/reports/ticket-summary" ||
                window.location.pathname === "/reports/cta-action" ? (
                  <img src={reportblueicon} alt="Reports" />
                ) : (
                  <img src={reports} alt="Reports" />
                )}
                <Link
                  to="/reports/campaign-summary"
                  className={
                    window.location.pathname === "/reports/videocall-summary" ||
                    window.location.pathname === "/reports/campaign-summary" ||
                    window.location.pathname === "/reports/phygital-summary" ||
                    window.location.pathname === "/reports/bell-chatDetails" ||
                    window.location.pathname ===
                      "/reports/staff-leader-board" ||
                    window.location.pathname ===
                      "/reports/appointment-summary" ||
                    window.location.pathname ===
                      "/reports/whatsapp-option-input-report" ||
                    window.location.pathname === "/reports/ticket-summary" ||
                    window.location.pathname === "/reports/cta-action"
                      ? "list-item-text"
                      : "list-item-text"
                  }
                ></Link>
                <ListItemText
                  className={
                    window.location.pathname === "/reports/videocall-summary" ||
                    window.location.pathname === "/reports/campaign-summary" ||
                    window.location.pathname === "/reports/phygital-summary" ||
                    window.location.pathname === "/reports/bell-chatDetails" ||
                    window.location.pathname ===
                      "/reports/staff-leader-board" ||
                    window.location.pathname ===
                      "/reports/appointment-summary" ||
                    window.location.pathname ===
                      "/reports/whatsapp-option-input-report" ||
                    window.location.pathname === "/reports/ticket-summary" ||
                    window.location.pathname === "/reports/cta-action"
                      ? "active active-text list-item-text"
                      : " list-item-text"
                  }
                  primary={"Reports"}
                />
              </h3>
            </ListItem>
            {showReports && (
              <ListItem button className="list-item-text">
                <ul className="sub-list">
                  {/* <li
                    className={
                      window.location.pathname === "/reports/videocall-summary"
                        ? "active active-text"
                        : ""
                    }
                  >
                    <Link to="/reports/videocall-summary">
                      {" "}
                      <ListItemText
                        className={
                          window.location.pathname ===
                          "/reports/videocall-summary"
                            ? "active active-text list-item-text"
                            : "list-item-text"
                        }
                        primary={" VideoCall Summary"}
                      />
                    </Link>
                  </li> */}
                  <li
                    className={
                      window.location.pathname === "/reports/campaign-summary"
                        ? "active active-text"
                        : ""
                    }
                  >
                    <Link to="/reports/campaign-summary">
                      {" "}
                      <ListItemText
                        className={
                          window.location.pathname ===
                          "/reports/campaign-summary"
                            ? "active active-text list-item-text"
                            : "list-item-text"
                        }
                        primary={" Campaign Summary"}
                      />
                    </Link>
                  </li>
                  <li
                    className={
                      window.location.pathname === "/reports/phygital-summary"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/reports/phygital-summary">
                      <ListItemText
                        className={
                          window.location.pathname ===
                          "/reports/phygital-summary"
                            ? "active active-text list-item-text"
                            : "list-item-text"
                        }
                        primary={" Phygital Summary"}
                      />
                    </Link>
                  </li>
                  <li
                    className={
                      window.location.pathname === "/reports/bell-chatDetails"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/reports/bell-chatDetails">
                      <ListItemText
                        className={
                          window.location.pathname ===
                          "/reports/bell-chatDetails"
                            ? "active active-text list-item-text"
                            : "list-item-text"
                        }
                        primary={" Bell Chat Details"}
                      />
                    </Link>
                  </li>
                  <li
                    className={
                      window.location.pathname === "/reports/staff-leader-board"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/reports/staff-leader-board">
                      {" "}
                      <ListItemText
                        className={
                          window.location.pathname ===
                          "/reports/staff-leader-board"
                            ? "active active-text list-item-text"
                            : "list-item-text"
                        }
                        primary={" Staff Leader Board"}
                      />
                    </Link>
                  </li>
                  <li
                    className={
                      window.location.pathname ===
                      "/reports/appointment-summary"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/reports/appointment-summary">
                      <ListItemText
                        className={
                          window.location.pathname ===
                          "/reports/appointment-summary"
                            ? "active active-text list-item-text"
                            : "list-item-text"
                        }
                        primary={"Appointment Summary"}
                      />{" "}
                    </Link>
                  </li>
                  <li
                    className={
                      window.location.pathname ===
                      "/reports/whatsapp-option-input-report"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/reports/whatsapp-option-input-report">
                      <ListItemText
                        className={
                          window.location.pathname ===
                          "/reports/whatsapp-option-input-report"
                            ? "active active-text list-item-text"
                            : "list-item-text"
                        }
                        primary={"Whatsapp Option Input Report"}
                      />{" "}
                    </Link>
                  </li>
                  <li
                    className={
                      window.location.pathname === "/reports/ticket-summary"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/reports/ticket-summary">
                      <ListItemText
                        className={
                          window.location.pathname === "/reports/ticket-summary"
                            ? "active active-text list-item-text"
                            : "list-item-text"
                        }
                        primary={"Ticket Summary"}
                      />{" "}
                    </Link>
                  </li>
                  {/* <li
                    className={
                      window.location.pathname === "/reports/mh-ticket-summary"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/reports/mh-ticket-summary">
                      <ListItemText
                        className={
                          window.location.pathname === "/reports/mh-ticket-summary"
                            ? "active active-text list-item-text"
                            : "list-item-text"
                        }
                        primary={"MH Ticket Summary"}
                      />{" "}
                    </Link>
                  </li> */}
                  <li
                    className={
                      window.location.pathname === "/reports/cta-action"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/reports/cta-action">
                      <ListItemText
                        className={
                          window.location.pathname === "/reports/cta-action"
                            ? "active active-text list-item-text"
                            : "list-item-text"
                        }
                        primary={" CTA Action"}
                      />
                    </Link>
                  </li>
                </ul>
              </ListItem>
            )}
          </List>
        </Drawer>
      </div>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: Draweropen,
        })}
      >
        {/* removing motherhood dashboard path */}
        {window.location.pathname === "/MotherhoodDashboard" ? (
          <Dashboard />
        ) : null}
         {window.location.pathname === "/dashboard" ||
        window.location.pathname === "/" ? (
          <Dashboard />
        ) : null}
        {window.location.pathname === "/reports/videocall-summary" ? (
          <VideoCallSummary />
        ) : null}
        {window.location.pathname === "/reports/campaign-summary" ? (
          <CampaignSummary />
        ) : null}
        {window.location.pathname === "/reports/phygital-summary" ? (
          <PhygitalSummary />
        ) : null}
        {window.location.pathname === "/reports/bell-chatDetails" ? (
          <BellChatDetails />
        ) : null}
        {window.location.pathname === "/reports/staff-leader-board" ? (
          <StaffLeaderBoard />
        ) : null}
        {window.location.pathname === "/reports/appointment-summary" ? (
          <AppointmentSummary />
        ) : null}
        {window.location.pathname ===
        "/reports/whatsapp-option-input-report" ? (
          <OptionInputSummary />
        ) : null}
        {window.location.pathname === "/reports/ticket-summary" ? (
          <BellTicketSummary />
        ) : null}
        {window.location.pathname === "/reports/mh-ticket-summary" ? (
          <MHTicketSummary />
        ) : null}
        {window.location.pathname === "/reports/cta-action" ? (
          <CtaAction />
        ) : null}
        {window.location.pathname === "/report-bulk-download" ? (
          <ReportBulkDownload />
        ) : null}
      </main>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

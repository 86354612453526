import APICaller from "../APICaller/APICaller";

class LoginService {
  constructor() {
    this.APICaller = new APICaller();
  }
  async Post(url) {
    return await this.APICaller.Post(url);
  }
  async GetWithHeader(url, headerData) {
    return await this.APICaller.GetWithHeader(url, headerData);
  }
  async PostWithData(url, inputData) {
    return await this.APICaller.PostWithData(url, inputData);
  }
  async PostWithParams(url, inputData) {
    return await this.APICaller.PostWithParams(url, inputData);
  }
  async PostWithHeader(url, headerData) {
    return await this.APICaller.PostWithHeader(url, headerData);
  }
  async PostWithHeaderandData(url, header, inputData) {
    return await this.APICaller.PostWithHeaderandData(url, header, inputData);
  }async PostWithHeaderandParams(url, header, inputParams) {
    return await this.APICaller.PostWithHeaderandParams(url, header, inputParams);
  }
  async GetWithHeaderandParams(url, header, inputData) {
    return await this.APICaller.GetWithHeaderandParams(url, header, inputData);
  }
}

export default LoginService;
